import React, {useEffect, useMemo, useState} from 'react';
import {Form, Input, Select, Space, Typography} from "antd";
import dayjs from "dayjs";

const {TextArea} = Input;
const {Text, Title} = Typography;

const BaseReservationForm = ({form = null, prefix = null, currentDate = null, calendarData = null, ...props}) => {
    if (prefix === null) {
        prefix = []
    }

    const [startHourFeedback, setStartHourFeedback] = useState("")
    const [endHourFeedback, setEndHourFeedback] = useState("")

    const hours = useMemo(() => [...Array(4 * 24).keys()]
            .map(i => dayjs('00:00', 'HH:mm')
                .add(15 * i, 'minute')
                .format('HH:mm')),
        []
    )

    const getDayInfo = () => {
        if (!currentDate || !calendarData) {
            return
        }

        const dayInfo = calendarData.week_days[dayjs(currentDate, "YYYY-MM-DD").weekday()]
        if (!dayInfo.enabled) {
            return
        }

        return dayInfo
    }

    const changeStartHour = (value) => {
        const dayInfo = getDayInfo()
        if (!dayInfo) {
            setStartHourFeedback("")
            return
        }

              // form.setFields([
              //     {
              //       name: "note", // required
              //       value: "myemail@@gmail.com",//optional
              //       errors: ["Invalid email", "XD"],
              //       warnings: ["Invalid edmail", "XdD"]
              //     },
              //   ]);

        //      form.setFields([
        //                   {
        //                     name: "note", // required
        //                     value: "myemail@@gmail.com",//optional
        //                     errors: null,
        //                     // warnings: ["Invalid edmail", "XdD"]
        //                   },
        //                 ]);

        // form.setFields([
        //     {
        //         name: [...prefix, "time_start"],
        //         warnings: dayjs(value, "HH:mm") < dayjs(dayInfo.reservation_time_start, "HH:mm:ss") ?
        //             ["Godzina rozpoczęcia jest wcześniej niż rozpoczęcie pracy"] : null
        //     }
        // ])

        setStartHourFeedback(dayjs(value, "HH:mm") < dayjs(dayInfo.reservation_time_start, "HH:mm:ss") ? "Godzina rozpoczęcia jest wcześniej niż rozpoczęcie pracy." : "")
    }

    const changeEndHour = (value) => {
        const dayInfo = getDayInfo()
        if (!dayInfo) {
            setEndHourFeedback("")
            return
        }

        setEndHourFeedback(dayjs(value, "HH:mm") > dayjs(dayInfo.reservation_time_end, "HH:mm:ss") ? "Godzina zakończenia jest później niż zakończenie pracy." : "")
    }

    useEffect(() => {
        console.log("changed date")
        changeStartHour(form.getFieldValue([...prefix, "time_start"]))
        changeEndHour(form.getFieldValue([...prefix, "time_end"]))
    }, [currentDate])

    return (<>
            <Space align="center">
                <Form.Item
                    name={[...prefix, "time_start"]}
                    label="Czas rozpoczęcia"
                    rules={[{required: true, message: "Wybierz godzinę!"}]}
                >
                    <Select
                        style={{
                            width: 120,
                        }}
                        options={hours.map(hour => ({value: hour, label: hour}))}
                        onChange={changeStartHour}
                    />
                </Form.Item>
                <Form.Item
                    name={[...prefix, "time_end"]}
                    label="Czas zakończenia"
                    rules={[{required: true, message: "Wybierz godzinę!"}]}
                >
                    <Select
                        style={{
                            width: 120,
                        }}
                        options={hours.map(hour => ({value: hour, label: hour}))}
                        onChange={changeEndHour}
                    />
                </Form.Item>
            </Space>

            <div>
                <Text type="warning" style={{marginTop: "-20px"}}>{startHourFeedback || endHourFeedback}</Text>
            </div>

            <Form.Item
                name={[...prefix, "name"]}
                label="Nazwa wydarzenia"
                rules={[{required: true, message: "Wprowadź nazwę wydarzenia!"}]}

            >
                <Input/>
            </Form.Item>

            <Form.Item
                name={[...prefix, "description"]}
                label="Opis wydarzenia"
                rules={[{required: true, message: "Wprowadź opis!"}]}
            >
                <TextArea rows={2}/>
            </Form.Item>
        </>
    )
}

export default BaseReservationForm;