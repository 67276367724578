import {Button, Checkbox, Col, Flex, Form, Input, Row, Select, Space, TimePicker, Typography} from 'antd'
import {redirect, useLoaderData, useNavigate} from "react-router-dom"
import dayjs from "dayjs";
import {useState} from "react";
import {useNotificationContext} from "../../../hooks/useNotificationContext";
import MultipleDatePicker from "../../../components/shared/MultipleDatePicker";

const {TextArea} = Input;
const {Option} = Select;
const {Title, Paragraph, Text} = Typography;

const TIME_FORMAT = 'HH:mm'
const WEEK_DAYS = [
    "Poniedziałek",
    "Wtorek",
    "Środa",
    "Czwartek",
    "Piątek",
    "Sobota",
    "Niedziela"
]

export const mapWeekNr = (week_nr) => {
    return WEEK_DAYS[week_nr]
}
export default function CalendarSettings() {
    const [form] = Form.useForm()
    const data = useLoaderData()
    const navigate = useNavigate()
    const {api} = useNotificationContext()

    const [selectedDate, setSelectedDate] = useState([])

    const [initialValues, setInitialValues] = useState(() => {
        const mappedData = {}

        for (const [key, dayData] of Object.entries(data.week_days)) {
            mappedData[`day_${dayData.day_nr}_checkbox`] = dayData.enabled
            mappedData[`day_${dayData.day_nr}_reservation_times`] = [
                dayjs(dayData.reservation_time_start, 'HH:mm'),
                dayjs(dayData.reservation_time_end, 'HH:mm')
            ]
        }

        setSelectedDate(data.disabled_dates)

        return mappedData
    })

    const onFinish = async (values) => {
        const week_days = {}
        for (let i = 0; i < 7; i++) {
            const times = values[`day_${i}_reservation_times`]

            week_days[i] = {
                day_nr: i,
                enabled: values[`day_${i}_checkbox`],
                reservation_time_start: times[0].format('HH:mm'),
                reservation_time_end: times[1].format('HH:mm')
            }
        }

        const response = await fetch(`/api/v1/calendar/`, {
            method: "PATCH",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                week_days,
                disabled_dates: selectedDate
            })
        })

        if (response.ok) {
            api['success']({
                message: 'Sukces',
                description: `Pomyślnie zaktualizowano dane.`
            })
        } else {
            response.json().then(data => api['error']({
                message: 'Błąd',
                description: data.detail.map((error) => (
                    <div>
                        <Paragraph>{error.loc[1]}: {error.msg}</Paragraph>
                    </div>
                ))
            }))
        }
    }

    return (
        <div className="calendar-details">
            <Title level={3}>Konfiguracja kalendarza</Title>

            <Form
                form={form}
                name="partyDetails"
                initialValues={initialValues}
                layout="vertical"
                labelCol={{
                    span: 16,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    maxWidth: 500,
                    padding: 24,
                    margin: 24,
                    background: "white"
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Title level={5} style={{marginTop: 0, paddingBottom: 10}}>Dni robocze oraz godziny pracy</Title>

                {Object.values(data.week_days).map(dayData => (
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                name={`day_${dayData.day_nr}_checkbox`}
                                valuePropName="checked"
                            >
                                <Checkbox>{mapWeekNr(dayData.day_nr)}</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                name={`day_${dayData.day_nr}_reservation_times`}>
                                <TimePicker.RangePicker
                                    // TODO:
                                    // disabled={!form.getFieldValue(`day_${dayData.day_nr}_checkbox`)}
                                    format={TIME_FORMAT}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                ))}

                <Title level={5} style={{marginTop: 0, paddingBottom: 10}}>Wyłączone dni</Title>

                <MultipleDatePicker
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                />

                <Form.Item
                    style={{marginBottom: 0}}>
                    <Flex justify="end" align="center">
                        <Space align="end">
                            <Button type="primary" htmlType="submit">
                                Zapisz
                            </Button>
                        </Space>
                    </Flex>
                </Form.Item>

            </Form>

        </div>
    )
}


export const calendarLoader = async () => {
    const response = await fetch('/api/v1/calendar/', {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        return redirect("/logout")
        // throw Error('Could not fetch the list of games')
    }

    return await response.json()
}
