import {Button, Col, Flex, Form, Input, InputNumber, Row, Select, Space, Typography} from 'antd'
import {redirect, useLoaderData, useNavigate} from "react-router-dom"
import {useNotificationContext} from "../../../hooks/useNotificationContext";

const {TextArea} = Input;
const {Option} = Select;
const {Title, Paragraph, Text} = Typography;

const MONTHS = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień"
]

export const mapPartyType = (party_type) => {
    switch (party_type) {
        case "room":
            return "Sala"
        case "campfire":
            return "Ognisko"
    }
}

export default function Parties() {
    const parties = useLoaderData()
    const navigate = useNavigate()
    const {api} = useNotificationContext()

    const onFinish = async (party_type, values) => {
        const response = await fetch(`/api/v1/parties/${party_type}`, {
            method: "PATCH",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })

        if (response.ok) {
            api['success']({
                message: 'Sukces',
                description: `Pomyślnie zaktualizowano dane obiektu ${mapPartyType(party_type)}.`
            })
        } else {
            response.json().then(data => api['error']({
                message: 'Błąd',
                description: data.detail.map((error) => (
                    <div>
                        <Paragraph>{error.loc[1]}: {error.msg}</Paragraph>
                    </div>
                ))
            }))
        }
    }

    return (
        <div className="party-details">
            <Title level={3}>Konfiguracja Sale/Ogniska</Title>

            <Row>
                {parties.map(party => (
                    <Col xs={24} lg={12} xxl={12} key={party.name}>
                        <Form
                            initialValues={party}
                            layout="vertical"
                            labelCol={{
                                span: 16,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                maxWidth: 500,
                                padding: 24,
                                margin: 24,
                                background: "white"
                            }}
                            onFinish={(values) => onFinish(party.name, values)}
                            autoComplete="off"
                        >
                            <Title level={5} style={{marginTop: 0, paddingBottom: 10}}>{mapPartyType(party.name)}</Title>

                            <Form.Item
                                label="Czas wyprzedzenia"
                                name="time_offset"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Wprowadź czas wyprzedzenia!',
                                    }]}>
                                <InputNumber
                                    addonAfter="min" min={0}/>
                            </Form.Item>

                            <Form.Item
                                label="Czas rezerwacji"
                                name="time"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Wprowadź czas rezerwacji!',
                                    }]}>
                                <InputNumber
                                    addonAfter="min" min={1}/>
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{span: 12}}
                                label="Koszt"
                                name="cost"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Wprowadź cenę!',
                                    }
                                ]}>
                                <InputNumber addonAfter="zł" min={0}/>
                            </Form.Item>

                            <Form.Item
                                name="disabled_months"
                                label="Niedostępne w miesiącach"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Wybierz miesiące"
                                    options={MONTHS.map((month, index) => ({
                                        value: index,
                                        label: month
                                    }))}
                                >

                                </Select>
                            </Form.Item>

                            <Form.Item
                                style={{marginBottom: 0}}>
                                <Flex justify="end" align="center">
                                    <Space align="end">
                                        <Button type="primary" htmlType="submit">
                                            Zapisz
                                        </Button>
                                    </Space>
                                </Flex>
                            </Form.Item>

                        </Form>
                    </Col>
                ))}

            </Row>

        </div>
    )
}


export const partiesLoader = async () => {
    const response = await fetch('/api/v1/parties/', {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        return redirect("/logout")
        // throw Error('Could not fetch the list of games')
    }

    return await response.json()
}
