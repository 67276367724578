import React from 'react';
import {Radio, Typography, Spin, Space} from "antd";
import useFetch from "../../../../hooks/useFetch";
import {mapPartyType} from "../../parties/Parties";

const {Text} = Typography;

const PartyPicker = ({value, onChange, event = null, game = null}) => {

    const {data, isPending, error} = useFetch('/api/v1/parties/')
    const onPartyTypeChange = (newPartyType) => {
        onChange?.(newPartyType)
    }

    return <Radio.Group
        value={value}
        onChange={onPartyTypeChange}
    >
        <Space direction="vertical">

        {data && data.map(party => (
                <Radio
                    key={party.name}
                    value={party.name}
                    disabled={(event && !event.room_campfire_reservations) || (game && !game[`${party.name}_reservation`])}
                >
                    {mapPartyType(party.name)}
                </Radio>
            )
        )}

        </Space>

        {isPending && < Spin> < /Spin>}
        {error && <Text type="danger"> Błąd: {error} < /Text>}

    </Radio.Group>
}

export default PartyPicker;


