import './App.css';
import Login from "./pages/Login";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom'
import AdminLayout from "./layouts/AdminLayout";
import Users, {usersLoader} from "./pages/admin/users/Users";
import ProtectedRoute from "./components/shared/ProtectedRoute";
import UserDetails, {userDetailsLoader} from "./pages/admin/users/UserDetails";
import Games, {gamesLoader} from "./pages/admin/games/Games";
import GameDetails, {gameDetailsLoader} from "./pages/admin/games/GameDetails";
import Events, {eventsLoader} from "./pages/admin/events/Events";
import EventDetails, {eventDetailsLoader} from "./pages/admin/events/EventDetails";
import Parties, {partiesLoader} from "./pages/admin/parties/Parties";
import CalendarSettings, {calendarLoader} from "./pages/admin/calendar/CalendarSettings";
import Logs, {logsLoader} from "./pages/admin/logs/Logs";
import ReservationLayout, {publicEventsLoader} from "./layouts/ReservationLayout";
import {NotificationProvider} from "./context/NotificationContext";
import {ReservationFormProvider} from "./context/ReservationContext";
import Reservations, {fakeLoader, reservationsLoader} from "./pages/admin/reservations/Reservations";
import ReservationDetails, {reservationDetailsLoader} from "./pages/admin/reservations/ReservationDetails";
import Vouchers, {vouchersLoader} from "./pages/admin/vouchers/Vouchers";
import VoucherDetails, {voucherDetailsLoader} from "./pages/admin/vouchers/VoucherDetails";
import PartyReservationDetails, {partyReservationDetailsLoader} from "./pages/admin/reservations/PartyReservationDetails";
import CustomReservationDetails, {
    customReservationDetailsLoader
} from "./pages/admin/reservations/CustomReservationDetails";
import Payment from "./pages/Payment";
import PaymentError from "./pages/PaymentError";
import PaymentExpire from "./pages/PaymentExpire";

const router = createBrowserRouter(
    createRoutesFromElements(

        <Route path="/">
            <Route index
                   element={<NotificationProvider><ReservationFormProvider><ReservationLayout /></ReservationFormProvider></NotificationProvider>}
                   loader={publicEventsLoader}
            />

            <Route path="login" element={<ProtectedRoute accessBy="non-authenticated"
                                                         redirectTo="/admin"><Login/></ProtectedRoute>}/>

            <Route path="admin" element={<ProtectedRoute accessBy="authenticated"
                                                         redirectTo="/login"><NotificationProvider><AdminLayout/></NotificationProvider></ProtectedRoute>}>

                <Route path="reservations">
                    <Route index element={<Reservations/>} loader={fakeLoader}/>

                    <Route path="create" element={<ReservationFormProvider><ReservationDetails/></ReservationFormProvider>} loader={reservationDetailsLoader}/>
                    <Route path="create-party" element={<PartyReservationDetails/>} loader={partyReservationDetailsLoader}/>
                    <Route path="create-custom" element={<ReservationFormProvider><CustomReservationDetails/></ReservationFormProvider>}/>

                    <Route path="edit/:id" element={<ReservationFormProvider><ReservationDetails/></ReservationFormProvider>} loader={reservationDetailsLoader}/>
                    <Route path="party/edit/:id" element={<PartyReservationDetails/>} loader={partyReservationDetailsLoader}/>
                    <Route path="custom/edit/:id" element={<ReservationFormProvider><CustomReservationDetails/></ReservationFormProvider>} loader={customReservationDetailsLoader}/>
                </Route>

                <Route path="vouchers">
                    <Route index element={<Vouchers/>} loader={vouchersLoader}/>
                    <Route path="edit/:id" element={<VoucherDetails/>} loader={voucherDetailsLoader}/>
                    <Route path="create" element={<VoucherDetails/>} loader={voucherDetailsLoader}/>
                </Route>

                <Route path="users">
                    <Route index element={<Users/>} loader={usersLoader}/>
                    <Route path="edit/:id" element={<UserDetails/>} loader={userDetailsLoader}/>
                    <Route path="create" element={<UserDetails/>} loader={userDetailsLoader}/>
                </Route>

                <Route path="games">
                    <Route index element={<Games/>} loader={gamesLoader}/>
                    <Route path="edit/:id" element={<GameDetails/>} loader={gameDetailsLoader}/>
                    <Route path="create" element={<GameDetails/>} loader={gameDetailsLoader}/>
                </Route>

                <Route path="events">
                    <Route index element={<Events/>} loader={eventsLoader}/>
                    <Route path="edit/:id" element={<EventDetails/>} loader={eventDetailsLoader}/>
                    <Route path="create" element={<EventDetails/>} loader={eventDetailsLoader}/>
                </Route>

                <Route path="parties" element={<Parties/>} loader={partiesLoader}/>
                <Route path="calendar" element={<CalendarSettings/>} loader={calendarLoader}/>
                <Route path="logs" element={<Logs/>} loader={logsLoader}/>

            </Route>

            <Route path="reservation">
                <Route path=":id/payment" element={<Payment />}/>
                <Route path=":id/payment-error" element={<PaymentError />}/>
                <Route path=":id/payment-expire" element={<PaymentExpire />}/>
            </Route>


        </Route>
    )
)


function App() {
    return (
        <RouterProvider router={router}/>
    );

}
export default App;
