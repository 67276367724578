import {Button, Checkbox, Flex, Form, Input, InputNumber, Radio, Select, Space, Typography} from 'antd'
import {useLoaderData, useNavigate, useParams} from "react-router-dom"
import {useNotificationContext} from "../../../hooks/useNotificationContext";
import {useEffect, useState} from "react";
import {mapPartyType} from "../parties/Parties";
import {MIN_TICKETS} from "../../../config";
import ReservationDatePicker from "./ReservationDatePicker";
import UserInfoForm from "../../../components/shared/UserInfoForm";
import {convertDates, convertDatesToString, convertErrorInfo, getFreeHoursData} from "../../../utils/utils";
import Summary from "./components/Summary";
import {useReservationFormContext} from "../../../hooks/useReservationFormContext";

const {TextArea} = Input;
const {Title, Paragraph, Text} = Typography;

export default function ReservationDetails() {
    const {id} = useParams()
    const {events, reservation, gamesParties} = useLoaderData()
    const navigate = useNavigate()
    const {api} = useNotificationContext()
    const {form, setSelectedEvent, setSelectedGame, setLoadedGamesParties, setSelectedParty, selectedParty, vouchersList, setVouchersList} = useReservationFormContext()

    const [error, setError] = useState("")
    const [help, setHelp] = useState("")

    const [games, setGames] = useState(gamesParties?.games || [])
    const [parties, setParties] = useState(gamesParties?.parties || null)

    const [hours, setHours] = useState([])

    const [trigger, setTrigger] = useState(false)
    const [summaryData, setSummaryData] = useState(null)  // TODO: load
    const [partyError, setPartyError] = useState("")

    const updateErrorMessage = (partyType) => {
        if (partyType) {
            if (getCurrentEvent() && !getCurrentEvent().room_campfire_reservations) {
                setPartyError("Sala/Ognisko jest zablokowane podczas tego typu wydarzenia.")
                return true
            } else if (getCurrentGame() && !getCurrentGame()[`${partyType}_reservation`]) {
                setPartyError("Wybrana opcja dodatkowa nie jest dostępna w tej grze. Usuń wybraną opcję lub zmień grę.")
                return true
            }
        }

        setPartyError("")

        return false
    }

    const refreshDatePicker = () => {
        console.log("Refresh trigger")
        setTrigger(!trigger)
    }

    const getCurrentEvent = () => {
        return events.find(event => event.id === form.getFieldValue("event_id"))
    }

    const getTotalTickets = () => {
        return (form.getFieldValue('normal_tickets') || 0) + (form.getFieldValue('reduced_tickets') || 0)
    }

    const getCurrentGame = () => {
        return games.find(game => game.id === form.getFieldValue("game_id"))
    }

    const loadGamesParties = async (value) => {
        const totalTickets = getTotalTickets()
        if (totalTickets < MIN_TICKETS) {
            setError("error")
            setHelp(`Łączna liczba biletów musi wynosić co najmniej ${MIN_TICKETS}!`)
            return
        }

        setError("")
        setHelp("")

        const gamesResponse = await fetch(`/api/v1/games-parties?tickets=${totalTickets}&event_id=${form.getFieldValue('event_id')}`, {
            credentials: 'include',
            headers: {
                'Content-Type': 'applications/json'
            }
        })

        gamesResponse.json().then(data => {
            setGames(data.games)
            setSelectedGame(getCurrentGame())
            setSelectedEvent(getCurrentEvent())

            setLoadedGamesParties(gamesParties)
            setParties(data.parties)
            setSelectedParty(reservation?.party_type)

            refreshDatePicker()
        })
    }

    const onFinish = async (values) => {
        values['user_phone'] = values['user_phone'].toString()
        values['panel_mode'] = true

        values['payment'] = values['is_full_payment'] ? summaryData.cost_with_discount : summaryData.advance
        values['cost'] = summaryData.cost_with_discount
        values['vouchers'] = vouchersList.join(",")

        console.log(values)

        const response = await fetch(`/api/v1/reservations/${id ? id : ''}`, {
            method: reservation ? "PATCH" : "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(convertDatesToString(values, ['date', "YYYY-MM-DD"]))
        })

        if (response.ok) {
            api['success']({
                message: 'Sukces',
                description: reservation ? `Pomyślnie zaktualizowano dane rezerwacji.` : `Pomyślnie utworzono rezerwację.`
            })
            navigate('/admin/reservations')
        } else {
            response.json().then(data => api['error']({
                message: 'Błąd',
                description: convertErrorInfo(data)
            }))
        }
    }

    const loadHours = (selectedDate) => {
        if (!selectedDate) {
            setHours([])
            return
        }

        const params = getFreeHoursData(form, selectedDate, id)

        fetch(`/api/v1/free-hours?${params}`)
            .then((response) => response.json())
            .then((json) => {
                setHours(json)
            })
    }

    const onDateChange = (date, dateString) => {
        form.setFieldValue('hour', null)
        loadHours(dateString)
    };

    useEffect(() => {
        if (!reservation) {
            return
        }

        setVouchersList(reservation.vouchers.split(','))
        loadGamesParties()
        loadHours(reservation.date.format('YYYY-MM-DD'))
    }, [])

    return (
        <div className="reservation-details">
            <Title level={3}>{reservation ? "Edycja rezerwacji" : "Nowa rezerwacja"}</Title>

            <div>

                <Form
                    form={form}
                    name="reservationDetails"
                    initialValues={convertDates(reservation, ["date", "YYYY-MM-DD"])}
                    layout="vertical"
                    labelCol={{
                        span: 16,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: 500,
                        padding: 24,
                        background: "white"
                    }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="event_id"
                        label="Wydarzenie"
                        rules={[{required: true, message: "Wybierz wydarzenie!"}]}
                    >
                        <Select
                            placeholder="Wybierz wydarzenie"
                            onChange={loadGamesParties}
                            options={events.map(event => ({
                                value: event.id,
                                label: event.name
                            }))}
                        >

                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Bilety normalne"
                        name="normal_tickets"
                        validateStatus={error ? "error" : ""}
                        help={help}
                    >
                        <InputNumber min={0} onChange={loadGamesParties}/>
                    </Form.Item>

                    <div style={{paddingTop: 5, marginBottom: 5}}>
                        <Text>Bilety ulgowe</Text><br/>
                    </div>
                    <Form.Item
                        name="reduced_tickets"
                        validateStatus={error ? "error" : ""}
                        help={help}
                    >
                        <InputNumber min={0} onChange={loadGamesParties}/>
                    </Form.Item>

                    {!getCurrentEvent() ?
                        <Text type="danger">Wybierz wydarzenie oraz określ liczbę biletów!</Text> : <></>}

                    <Form.Item
                        name="game_id"
                        label="Gra"
                    >
                        <Select
                            disabled={!getCurrentEvent() || getTotalTickets() < MIN_TICKETS}
                            placeholder="Wybierz grę"
                            onChange={(e) => {
                                setSelectedGame(getCurrentGame())
                                refreshDatePicker()
                            }}
                            options={games && games.map(game => ({
                                value: game.id,
                                label: game.name
                            }))}
                            rules={[
                                {required: true, message: "Wybierz grę!"}
                            ]}
                        >
                        </Select>
                    </Form.Item>


                    <Form.Item name="party_type" label="Dodatkowe opcje">
                        <Radio.Group
                            defaultValue={null}
                            disabled={!getCurrentEvent() || getTotalTickets() < MIN_TICKETS || !getCurrentEvent().room_campfire_reservations}
                        >
                            <Radio
                                value={null}
                                onChange={(e) => {
                                    updateErrorMessage(e.target.value)
                                    setSelectedParty(e.target.value)
                                    refreshDatePicker()
                                }}
                            >Brak</Radio><br/>

                            {parties && games && parties.map(party => {
                                const foundGame = getCurrentGame()
                                const disabled = (getCurrentEvent() && !getCurrentEvent().room_campfire_reservations) || (foundGame && !foundGame[`${party.name}_reservation`])

                                return (
                                    <Radio
                                        key={party.name}
                                        style={{paddingTop: 20}}
                                        value={party.name}
                                        disabled={disabled}
                                        onChange={(e) => {
                                            updateErrorMessage(e.target.value)
                                            setSelectedParty(e.target.value)
                                            refreshDatePicker()
                                        }}
                                    >
                                        {mapPartyType(party.name)} {party.time}min po grze
                                        (Standardowa cena: {party.cost}zł)<br/>
                                    </Radio>
                                )
                            })}

                        </Radio.Group>
                    </Form.Item>

                    {partyError ? <p><Text type="danger">{partyError}</Text></p> : <></>}

                    <Form.Item
                        label="Data rezerwacji"
                        name="date"
                        rules={[{required: true, message: "Wybierz datę!"}]}
                    >
                        <ReservationDatePicker
                            dependence={["event_id", "game_id", "party_type", "normal_tickets", "reduced_tickets"]}
                            form={form}
                            trigger={trigger}
                            reservation_id={id}
                            onChange={onDateChange}
                            disabled={!getCurrentEvent() || !getCurrentGame()}
                        />
                    </Form.Item>

                    <Form.Item
                        name="hour"
                        label="Godzina"
                        rules={[{required: true, message: "Wybierz godzinę!"}]}
                    >
                        <Select
                            style={{
                                width: 120,
                            }}
                            onChange={() => refreshDatePicker()}
                            loading={!hours}
                            disabled={!hours}
                            options={hours && hours.map(hour => ({value: hour, label: hour}))}
                        />
                    </Form.Item>

                    <UserInfoForm
                        required={true}
                        selectedParty={selectedParty}
                        showDescriptions={false}
                    />

                    <Summary
                        reservation={reservation}
                        trigger={trigger}
                        refreshTrigger={refreshDatePicker}
                        setResultData={setSummaryData}
                        useStandardCalculations={true}
                    />

                    {!reservation &&
                        <Form.Item name="generate_payment">
                            <Checkbox>Wyślij mailem link do płatności</Checkbox>
                        </Form.Item>
                    }

                    <Form.Item
                        style={{marginBottom: 0}}>
                        <Flex justify="end" align="center">
                            <Space align="end">
                                <Button type="default" onClick={() => navigate('/admin/reservations')}>
                                    Anuluj
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    {reservation ? "Zapisz" : "Utwórz"}
                                </Button>
                            </Space>
                        </Flex>
                    </Form.Item>

                </Form>

            </div>

        </div>
    )
}


export const reservationDetailsLoader = async ({params}) => {
    const eventsResponse = await fetch(`/api/v1/public-events`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!eventsResponse.ok) {
        throw Error('Nie można pobrać listy wydarzeń.')
    }

    const {id} = params

    if (!id) {
        return {
            events: await eventsResponse.json(),
            reservation: null
        }
    }

    const response = await fetch(`/api/v1/reservations/${id}`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        throw Error('Nie można pobrać danych tej rezerwacji.')
    }

    const reservation = await response.json()

    const gamesResponse = await fetch(`/api/v1/games-parties?tickets=${reservation.reduced_tickets + reservation.normal_tickets}&event_id=${reservation.event_id}`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    return {
        events: await eventsResponse.json(),
        reservation: reservation,
        gamesParties: await gamesResponse.json()
    }
}