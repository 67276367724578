import {Button, Flex, Popconfirm, Space, Table, Typography} from 'antd';
import {NavLink, redirect, useLoaderData, useNavigate} from "react-router-dom"
import {useState} from "react";
import {useMobileBreakpoint} from "../../../hooks/useMobileBreakpoint";
import {useNotificationContext} from "../../../hooks/useNotificationContext";

const {Title, Text} = Typography;
export default function Games() {
    const data = useLoaderData()
    const {isMobile} = useMobileBreakpoint()
    const navigate = useNavigate()
    const {api} = useNotificationContext()
    const [dataSource, setDataSource] = useState(data.map(game => ({
        key: game.id,
        name: game.name,
        outside_game: game.outside_game,
        room_reservation: game.room_reservation,
        campfire_reservation: game.campfire_reservation,
        reservation_time: game.reservation_time,
        game_time: game.game_time,
        display_order: game.display_order,
    })))

    const columns = [
        {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Gra zewnętrzna',
            dataIndex: 'outside_game',
            key: 'outside_game',
            render: (_, record) => (
                <Space size="middle">
                    {record.outside_game ? "Tak" : "Nie"}
                </Space>
            )
        },
        {
            title: 'Rezerwacja sali',
            dataIndex: 'room_reservation',
            key: 'room_reservation',
            render: (_, record) => (
                <Space size="middle">
                    {record.room_reservation ? "Tak" : "Nie"}
                </Space>
            )
        },
        {
            title: 'Rezerwacja ogniska',
            dataIndex: 'campfire_reservation',
            key: 'campfire_reservation',
            render: (_, record) => (
                <Space size="middle">
                    {record.campfire_reservation ? "Tak" : "Nie"}
                </Space>
            )
        },
        {
            title: 'Czas rezerwacji',
            dataIndex: 'reservation_time',
            key: 'reservation_time',
            render: (_, record) => (
                <Space size="middle">
                    <Text>{record.reservation_time} min</Text>
                </Space>
            )
        },
        {
            title: 'Czas gry',
            dataIndex: 'game_time',
            key: 'game_time',
            render: (_, record) => (
                <Space size="middle">
                    <Text>{record.game_time} min</Text>
                </Space>
            )
        },
        {
            title: 'Pozycja wyświetlania',
            dataIndex: 'display_order',
            key: 'display_order'
        },
        {
            title: 'Akcje',
            key: 'actions',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Button>
                        <NavLink to={`edit/${record.key.toString()}`}>Edytuj</NavLink>
                    </Button>
                    <Popconfirm
                        placement="bottomRight"
                        title={"Usunięcie gry"}
                        description={`Czy chcesz usunąć grę '${record.name}'?`}
                        onConfirm={() => deleteGame(record.key, record.name)}
                        okText="Tak"
                        cancelText="Nie"
                    >
                        <Button danger>Usuń</Button>
                    </Popconfirm>
                </Space>
            )
        }
    ]

    const deleteGame = async (gameId, username) => {
        const response = await fetch(`/api/v1/games/${gameId}`, {
            credentials: 'include',
            method: "DELETE",
            headers: {
                'Content-Type': 'applications/json'
            }
        })

        if (!response.ok) {
            const error = response.status === 500 ? "Nieznany błąd!" : await response.json()

            api['error']({
                message: 'Błąd',
                description: error
            })

        } else {
            api['success']({
                message: 'Sukces',
                description: `Usunięto grę '${username}'.`
            })

            setDataSource(dataSource.filter((user) => user.key !== gameId))
        }
    }

    return (
        <div className="games">
            <Flex justify="space-between" align="center">
                <Title level={3}>Gry</Title>
                <Button type="primary" onClick={() => navigate('/admin/games/create')}>Utwórz</Button>
            </Flex>
            <Table
                dataSource={dataSource}
                columns={isMobile ? columns.filter(c => c.key === "name" || c.key === "actions") : columns}
            />
        </div>
    )
}


export const gamesLoader = async () => {
    const response = await fetch('/api/v1/games/', {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        return redirect("/logout")
        // throw Error('Could not fetch the list of games')
    }

    return await response.json()
}
