import { useAuthContext } from './useAuthContext'
import {Navigate, redirect} from "react-router-dom";

export const useLogout = () => {
    const {setUser} = useAuthContext()
    const logout = async () => {
        const response = await fetch('/api/v1/auth/logout', {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'applications/json'
            }
        })

        if (response.ok) {
            setUser(null)
            redirect("/login")
        }

    }

    return { logout }
}