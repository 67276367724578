import {Button, Card, Checkbox, Col, Form, Input, Radio, Row, Space, Typography} from "antd";
import {MinusCircleOutlined} from '@ant-design/icons';
import Meta from "antd/es/card/Meta";
import React, {useMemo, useState} from "react";
import {useReservationFormContext} from "../../../hooks/useReservationFormContext";
import useFetch from "../../../hooks/useFetch";
import {useNotificationContext} from "../../../hooks/useNotificationContext";
import {useNavigate} from "react-router-dom";
import UserInfoForm from "../../shared/UserInfoForm";
import {mapPartyType} from "../../../pages/admin/parties/Parties";
import {convertErrorInfo} from "../../../utils/utils";
import Summary from "../../../pages/admin/reservations/components/Summary";

const {TextArea} = Input;
const {Text, Title, Paragraph} = Typography;


const ClientInfoPage = ({prevPage, game, event, party}) => {
    const navigate = useNavigate()

    const {api} = useNotificationContext()
    const {form, selectedGame, loadedGamesParties, vouchersList} = useReservationFormContext()

    const [trigger, setTrigger] = useState(false)
    const [summaryData, setSummaryData] = useState(null)

    const refresh = () => {
        setTrigger(!trigger)
        console.log("REFRESH")
    }

    const onFinish = async (values) => {
        form.setFieldValue('payment', form.getFieldValue('is_full_payment') ? summaryData.cost_with_discount : summaryData.advance)
        console.log("vouchersList", vouchersList)
        const content = form.getFieldsValue(true)
        content['user_phone'] = form.getFieldValue('user_phone').toString()
        content['vouchers'] = vouchersList.join(",")

        if (!form.getFieldValue('is_invoice')) {
            delete content.company_nip
            delete content.company_name
            delete content.address
            delete content.city
            delete content.zip_code
        }

        const response = await fetch(`/api/v1/reservations/`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content)
        })

        if (response.ok) {
            // api['success']({
            //     message: 'Sukces',
            //     description: `Pomyślnie utworzono rezerwacje!`
            // })

            response.json().then(data => navigate(`/reservation/${data.reservation_id}/payment`))

        } else {
            response.json().then(data => {
                api['error']({
                    message: 'Błąd',
                    description: convertErrorInfo(data)
                })
            })
        }
    }

    return (
        <Row justify="center" align="middle" style={{marginTop: 20, marginBottom: 20}}>
            <Col xs={24} sm={24} md={24} lg={24}>
                <Card>

                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={(x) => console.log(x)}
                    >

                        <Meta
                            title="Wypełnij swoje dane"
                            style={{marginBottom: '2vh'}}
                        />

                        <UserInfoForm selectedParty={form.getFieldValue('party_type')}/>

                        <Title level={4}>Płatność</Title>

                        <Summary
                            form={form}
                            trigger={trigger}
                            refreshTrigger={refresh}
                            selectedGame={selectedGame}
                            setResultData={setSummaryData}
                            useStandardCalculations={true}
                        />

                        <Text type="secondary">Po dokonaniu opłaty z terminu można zrezygnować
                            najpóźniej 72 godziny przed podanym czasem rezerwacji, w innym przypadku
                            opłata nie jest zwracana!</Text>

                        <br/> <br/>

                        <Form.Item
                            name="status"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error('Akceptuj regulamin!')),
                                },
                            ]}
                            valuePropName="checked"
                        >
                            <Checkbox>
                                Akceptuję regulamin
                                <br/>
                                <Text type="secondary">Regulamin: <a
                                    href={`${process.env.PUBLIC_URL}/regulamin-serwisu-lasertagpoznan-pl.pdf`}
                                    target="_blank"
                                >Link</a></Text>
                            </Checkbox>
                        </Form.Item>

                        <img alt="Logo" src={`${process.env.PUBLIC_URL}/baner_600x120_bez_pp.png`}
                             style={{maxWidth: "100%"}}/>

                        <br/>

                        <Row justify="end">
                            <Col>
                                <Space>
                                    <Button onClick={() => prevPage()}>WRÓĆ</Button>
                                    <Button type="primary" htmlType="submit">WYBIERAM I PŁACĘ</Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>

                </Card>
            </Col>
        </Row>
    )
}

export default ClientInfoPage;