import {Alert, Button, Card, Col, Form, Radio, Row, Space, Typography} from "antd";

import Meta from "antd/es/card/Meta";
import {mapPartyType} from "../../../pages/admin/parties/Parties";
import GameCard from "./GameCard";
import useFetch from "../../../hooks/useFetch";
import {useReservationFormContext} from "../../../hooks/useReservationFormContext";
import {useState} from "react";
import localeData from 'dayjs/plugin/localeData'
import dayjs from "dayjs";

const {Text, Title} = Typography;


export const DESCRIPTIONS = {
    room: <Text type="secondary">Po grze można skorzystać z ogrzewanej kominkiem sali, w
        której mieści się
        do 40 osób. W cenie jest tylko wynajem sali, jedzenie i napoje we własnym
        zakresie.</Text>,
    campfire: <Text type="secondary">Podczas wiosenno-letnich miesięcy można spędzić czas na
        zewnątrz, przy
        ognisku. W razie deszczu impreza przenoszona jest do sali wewnątrz fortu. W
        cenie: rozpalenie ogniska, drewno, kije do kiełbas</Text>
}

const GamePage = ({event, prevPage, nextPage}) => {
    dayjs.extend(localeData)

    const months = dayjs.months()
    const {form, selectedGame, setSelectedGame, loadedGamesParties, setLoadedGamesParties} = useReservationFormContext()
    const totalTickets = (form.getFieldValue('normal_tickets') || 0) + (form.getFieldValue('reduced_tickets') || 0)
    useFetch(loadedGamesParties ? null : `/api/v1/games-parties?tickets=${totalTickets}&event_id=${event.id}`, (status, data) => {
        setLoadedGamesParties(data)
    })
    const [selectGameError, setSelectGameError] = useState(false)
    const [partyError, setPartyError] = useState("")

    const updateErrorMessage = (partyType) => {
        if (partyType) {
            if (!event.room_campfire_reservations) {
                setPartyError("Sala/Ognisko jest zablokowane podczas tego typu wydarzenia.")
                return true
            } else if (selectedGame && !selectedGame[`${partyType}_reservation`]) {
                setPartyError("Wybrana opcja dodatkowa nie jest dostępna w tej grze. Usuń wybraną opcję lub zmień grę.")
                return true
            }
        }

        setPartyError("")

        return false
    }
    const tryNextPage = () => {
        if (!selectedGame) {
            setSelectGameError(true)
            return
        }

        if (updateErrorMessage(form.getFieldValue('party_type'))) {
            return
        }

        form.setFieldValue('game_id', selectedGame.id)
        nextPage()
    }

    return (
        <Row justify="center" align="middle" style={{marginTop: 20}}>
            <Col xs={24} sm={24} md={24} lg={12}>
                <Card>
                    <Meta
                        title="Wybierz rodzaj gry"
                        description="Dostępne rodzaje gier dla wybranej liczby osób."
                    />
                </Card>

                <Row gutter={[16, 16]} style={{paddingTop: 15, paddingBottom: 15}}>

                    {loadedGamesParties && loadedGamesParties.games.map(game => (
                        <GameCard
                            key={game.id}
                            game={game}
                            selectedGame={selectedGame}
                            setSelectedGame={setSelectedGame}
                            setSelectGameError={setSelectGameError}
                        />
                    ))}

                </Row>

                <Form layout="vertical" form={form}>
                    <Card>
                        <Meta
                            title="Wybierz dodatkowe opcje"
                            style={{marginBottom: '2vh'}}
                        />

                        {event.room_campfire_reservations ? <></> :
                            <Alert
                                style={{marginBottom: 15}}
                                message="Sala/Ognisko jest zablokowane podczas tego typu wydarzenia."
                                type="warning"/>
                        }

                        {
                            event.room_campfire_reservations && selectedGame && (!selectedGame.room_reservation || !selectedGame.campfire_reservation) ?
                                <Alert
                                    style={{marginBottom: 15}}
                                    message="Niektóre opcje są zablokowane dla tego rodzaju gry."
                                    type="warning"/>
                                : <></>
                        }

                        {() => console.log(dayjs.locale())}

                        <Form.Item name="party_type">
                            <Radio.Group defaultValue={null} disabled={!event.room_campfire_reservations}>
                                <Radio value={null} onChange={(e) => updateErrorMessage(e.target.value)}>Brak</Radio>

                                {loadedGamesParties && loadedGamesParties.parties.map(party => {
                                    const disabled = !event.room_campfire_reservations || (selectedGame && !selectedGame[`${party.name}_reservation`])
                                    const disabledMonths = party.disabled_months
                                        .sort((a, b) => a - b)
                                        .map(month_nr => months[month_nr])
                                        .map(month => month.charAt(0).toUpperCase() + month.slice(1))
                                        .join(", ")

                                    return (
                                        <Radio
                                            onChange={(e) => updateErrorMessage(e.target.value)}
                                            key={party.name}
                                            style={{paddingTop: 20}}
                                            value={party.name}
                                            disabled={disabled}
                                        >
                                            {mapPartyType(party.name)} {party.time} min po grze
                                            ({party.cost}zł)<br/>
                                            {DESCRIPTIONS[party.name]}
                                            {party.disabled_months.length > 0 ? <br/> : <></>}
                                            {party.disabled_months.length > 0 ? <Text type="warning">Niedostępne w
                                                miesiącach: {disabledMonths}</Text> : <></>}
                                        </Radio>
                                    )
                                })}

                            </Radio.Group>
                        </Form.Item>

                        {selectGameError ? <p><Text type="danger">Wybierz grę!</Text></p> : <></>}
                        {partyError ? <p><Text type="danger">{partyError}</Text></p> : <></>}

                        <Row justify="end">
                            <Col>
                                <Space>
                                    <Button onClick={() => prevPage()}>WRÓĆ</Button>
                                    <Button type="primary" htmlType="submit" onClick={tryNextPage}>WYSZUKAJ GRĘ</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                </Form>

            </Col>
        </Row>
    )
}

export default GamePage;