import {Button, Card, Col, Form, InputNumber, Row, Space, Typography} from "antd";

import Meta from "antd/es/card/Meta";
import {useReservationFormContext} from "../../../hooks/useReservationFormContext";
import {useState} from "react";

const {Text} = Typography;

const TicketsPage = ({prevPage, nextPage}) => {
    const {form} = useReservationFormContext()
    const [error, setError] = useState("")
    const [help, setHelp] = useState("")

    const handleSubmit = (values) => {
        if (!onValuesChange(values)) {
            return
        }

        nextPage()
    }

    const onValuesChange = (values) => {
        const totalTickets = (form.getFieldValue('normal_tickets') || 0) + (form.getFieldValue('reduced_tickets') || 0)

        if (totalTickets >= 4) {
            setError("")
            setHelp("")
            return true
        }

        setError("error")

        if (totalTickets > 0) {
            setHelp("Łączna liczba biletów musi wynosić co najmniej 4!")
            return false
        }

        setHelp("Wpisz liczbę biletów")
        return false
    }

    return (
        <Row justify="center" align="middle" style={{marginTop: 20}}>
            <Col xs={24} sm={24} md={24} lg={12}>
                <Card>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={handleSubmit}
                        onValuesChange={onValuesChange}
                    >

                        <Meta
                            title="Wpisz liczbę osób"
                            description="Jeżeli nie jesteś pewien ostatecznej liczby osób podaj szacunkową ilość.
                        Obsługujemy grupy od 4 do 48 osób."
                            style={{marginBottom: '2vh'}}
                        />

                        <Form.Item
                            label="Bilety normalne"
                            name="normal_tickets"
                            validateStatus={error ? "error" : ""}
                            help={help}
                        >
                            <InputNumber min={0}/>
                        </Form.Item>

                        <div style={{paddingTop: 5, marginBottom: 5}}>
                            <Text>Bilety ulgowe</Text><br/>
                            <Text type="secondary">Przeznaczone są dla dzieci, gimnazjalistów, licealistów i
                                studentów.
                            </Text>
                        </div>
                        <Form.Item
                            name="reduced_tickets"
                            validateStatus={error ? "error" : ""}
                            help={help}
                        >

                            <InputNumber min={0}/>
                        </Form.Item>

                        <Row justify="end">
                            <Col>
                                <Space>
                                    <Button onClick={() => prevPage()}>WRÓĆ</Button>
                                    <Button type="primary" htmlType="submit">WYSZUKAJ GRĘ</Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>

                </Card>
            </Col>
        </Row>
    )
}

export default TicketsPage;