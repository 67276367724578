import EventCard from "./EventCard";
import {Modal, Typography} from "antd";

const {Title} = Typography;

const config = {
    title: 'Uwaga!',
    content: (
        <p>Ten rodzaj imprezy należy zarezerwować<br/> telefonicznie pod
            numerem 570 931 931 <br/>lub mailowo pod adresem office@lasertagpoznan.pl<br/><br/>
            Do usłyszenia!</p>
    ),
}

const EventPage = ({nextPage, events,}) => {
    const [modal, contextHolder] = Modal.useModal()

    const showModal = () => {
        modal.warning(config)
    }

    return <div className="events">

        {events.map(event => (<EventCard
            key={event.id}
            data={event}
            nextPage={nextPage}
            showModal={showModal}
        />))}

        {contextHolder}

    </div>
}

export default EventPage;