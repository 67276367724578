import {Button, DatePicker, Flex, Form, Input, InputNumber, Radio, Space, Modal, Typography} from 'antd'
import {useLoaderData, useNavigate, useParams} from "react-router-dom"
import {useNotificationContext} from "../../../hooks/useNotificationContext";
import {useState} from "react";
import {convertDates, convertErrorInfo} from "../../../utils/utils";

const {Title, Text} = Typography;

export default function VoucherDetails() {
    const [form] = Form.useForm()
    const [modal, contextHolder] = Modal.useModal();
    const {id} = useParams()
    const {voucher} = useLoaderData()
    const navigate = useNavigate()
    const {api} = useNotificationContext()
    const [valueType, setValueType] = useState(voucher ? voucher.is_voucher : true)
    const onFinish = async (values) => {
        if (values.expires_at) {
            values.expires_at = values.expires_at.format("YYYY-MM-DD")
        }

        if (!values.amount) {
            values.amount = 1
        }

        const genCode = values.amount > 1 || !values.code || !values.code.trim()
        if (genCode) {
            delete values.code
        }

        const response = await fetch(`/api/v1/vouchers/${id ? id : ''}`, {
            method: voucher ? "PATCH" : "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })

        if (response.ok) {
            if (genCode) {
                response.json().then(data => {
                    modal.success({
                        title: "Wygenerowane kody voucherów",
                        content: data.map((item, i) => {
                            return <p key={i}>{item.code}</p>;
                        }),
                        onOk() {
                            navigate('/admin/vouchers')
                        }
                    })
                })
            } else {
                api['success']({
                    message: 'Sukces',
                    description: voucher ? `Pomyślnie zaktualizowano dane vouchera ${values.code}.` : `Pomyślnie utworzono voucher '${values.code}'.`
                })
                navigate('/admin/vouchers')
            }

        } else {
            response.json().then(data => {
                api['error']({
                    message: 'Błąd',
                    description: convertErrorInfo(data)
                })
            })
        }
    }

    return (
        <div className="user-details">
            <Title level={3}>{voucher ? "Edycja vouchera" : "Nowy voucher"}</Title>

            <div>

                <Form
                    form={form}
                    name="voucherDetails"
                    initialValues={convertDates(voucher, ["expires_at", "YYYY-MM-DD"]) || {is_voucher: true}}
                    layout="vertical"
                    labelCol={{
                        span: 20,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: 600,
                        padding: 24,
                        background: "white"
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Text>Kod</Text><br/>
                    {!id &&
                    <Text type="secondary">Pozostaw puste, aby wygenerować losowy kod.</Text>}
                    <Form.Item
                        name="code"
                        rules={[
                            {
                                min: 3,
                                message: "Kod musi zawierać min 3 znaki"
                            }
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item name="is_voucher" label="Typ"
                               rules={[
                                   {
                                       required: true,
                                       message: 'Wybierz typ vouchera!',
                                   }]}>
                        <Radio.Group
                            onChange={(e) => setValueType(e.target.value)}>
                            <Radio value={true}>Voucher</Radio>
                            <Radio value={false}>Kod rabatowy</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Wartość"
                        name="value"
                        rules={[
                            {
                                required: true,
                                message: 'Wprowadź wartość vouchera!',
                            }]}>
                        <InputNumber
                            addonAfter={valueType ? "zł" : "%"} min={1}/>
                    </Form.Item>

                    {valueType &&
                        <Form.Item
                            label="Liczba biletów"
                            name="tickets_amount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Wprowadź liczbę biletów!',
                                }]}>
                            <InputNumber min={1}/>
                        </Form.Item>}

                    <Form.Item
                        label="Ważny do"
                        name="expires_at"
                    >
                        <DatePicker
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>

                    {!id &&
                        <>
                            <Text>Liczba nowych voucherów</Text><br/>
                            <Text type="secondary">Wypełnij jeżeli wygenerować więcej niż 1 voucher.<br/> Kod vouchera
                                zostanie wygenerowany losowo.</Text>
                            <Form.Item name="amount">
                                <InputNumber min={1}/>
                            </Form.Item>
                        </>

                    }

                    <Form.Item
                        style={{marginBottom: 0}}>
                        <Flex justify="end" align="center">
                            <Space align="end">
                                <Button type="default" onClick={() => navigate('/admin/vouchers')}>
                                    Anuluj
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    {voucher ? "Zapisz" : "Utwórz"}
                                </Button>
                            </Space>
                        </Flex>
                    </Form.Item>

                </Form>

            </div>

            {contextHolder}

        </div>

    )
}


export const voucherDetailsLoader = async ({params}) => {
    const {id} = params

    if (!id) {
        return {
            voucher: null,
        }
    }

    const response = await fetch(`/api/v1/vouchers/${id}`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        throw Error('Nie można pobrać danych tego vouchera.')
    }

    return {
        voucher: await response.json()
    }
}