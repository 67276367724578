import React, {useState} from 'react';
import {Button, DatePicker, Flex, Space, Typography} from 'antd';
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";

const {Text} = Typography;


const MultipleDatePicker = ({selectedDate, setSelectedDate, ...props}) => {
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
        weekStart: 1
    })

    const [open, setOpen] = useState(false)

    const onValueChange = (date) => {
        const newDate = date.format("YYYY-MM-DD")
        if (selectedDate.includes(newDate)) {
            setSelectedDate([...selectedDate.filter(item => item !== newDate)])
        } else {
            setSelectedDate([...selectedDate, newDate])
        }
    };

    const cellRender = React.useCallback((current, info) => {
        if (info.type !== 'date') {
            return info.originNode
        }

        if (typeof current === 'number') {
            return <div className="ant-picker-cell-inner">{current}</div>
        }

        const isSelected = selectedDate.includes(current.format("YYYY-MM-DD"))
        let selectStyle = isSelected ?
            {
                position: 'relative',
                zIndex: 2,
                display: 'inlineBlock',
                width: "24px",
                height: "22px",
                lineHeight: "22px",
                backgroundColor: "red",
                color: "#fff",
                margin: "auto",
                borderRadius: "2px",
                transition: "background 0.3s, border 0.3s"
            }
            : {}
        return (<div style={selectStyle}> {current.date()}  </div>)

    }, [selectedDate]);

    return (
        <>
            <div className="multipleDatePicker">
                <DatePicker
                    placeholder="Kilknij i wyświetl"
                    open={open}
                    // style={{visibility: "hidden", width: 0}}
                    cellRender={cellRender}
                    onChange={onValueChange}
                    showToday={false}
                    place
                    value={""}
                    onOpenChange={() => setOpen(true)}
                    renderExtraFooter={() => <Flex justify="center" align="center" style={{padding: 5}}>
                        <Button type="link" onClick={() => setOpen(false)}>Zamknij</Button>
                    </Flex>}
                    {...props}
                />
                {/*<Button type='primary' onClick={() => console.log(selectedDate)}>xxx</Button>*/}
            </div>
        </>
    )

}

export default MultipleDatePicker