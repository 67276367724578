import {Card, Col, Flex, Layout, Row, Space, Typography} from "antd";
import {CloseCircleTwoTone} from '@ant-design/icons';
import Meta from "antd/es/card/Meta";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";

const {Text, Title} = Typography;

const PaymentExpire = () => {

    return (
        <Layout style={{height: "100vh", backgroundColor: "#032244"}}>

            <Flex align="center" justify="center" style={{marginTop: 40}}>
                <div className="demo-logo-vertical">
                    <img src={`${process.env.PUBLIC_URL}/lasertag-logo-white.svg`} style={{width: "200px"}}/>
                </div>
            </Flex>

            <Row justify="center" align="middle" style={{marginTop: 20}}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card>
                        <Meta
                            style={{marginBottom: '2vh'}}
                        />

                        <Space>
                            <CloseCircleTwoTone twoToneColor="#c41a45"/>
                            <Title level={4} style={{margin: 0}}>Rezerwacja wygasła! Płatność nie została dokonana w terminie.</Title>
                        </Space>

                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}

export default PaymentExpire