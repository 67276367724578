import React from 'react';
import {Link, NavLink, Outlet, useLocation} from 'react-router-dom'
import {useAuthContext} from '../hooks/useAuthContext'

import {
    BuildOutlined,
    CalendarOutlined,
    CreditCardOutlined,
    EyeOutlined,
    FireOutlined,
    LineChartOutlined,
    NodeIndexOutlined,
    OrderedListOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Button, Flex, Layout, Menu, Space, Typography} from 'antd';
import {GroupsContextProvider} from "../context/GroupsContext";
import {useNotificationContext} from "../hooks/useNotificationContext";

import Cookies from 'js-cookie';
import {useLogout} from "../hooks/useLogout";

import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";

require('dayjs/locale/pl')

const {Text} = Typography;
const {Header, Content, Footer, Sider} = Layout;

const restMenu = [
    {
        key: 'vouchers',
        icon: React.createElement(CreditCardOutlined),
        label: <NavLink to="vouchers">Vouchery</NavLink>
    },
    {
        key: 'users',
        icon: React.createElement(UserOutlined),
        label: <NavLink to="users">Użytkownicy</NavLink>
    },
    {
        key: 'events',
        icon: React.createElement(BuildOutlined),
        label: <NavLink to="events">Wydarzenia</NavLink>
    },
    {
        key: 'games',
        icon: React.createElement(NodeIndexOutlined),
        label: <Link to="games">Gry</Link>
    },
    {
        key: 'parties',
        icon: React.createElement(FireOutlined),
        label: <Link to="parties">Sala/Ognisko</Link>
    },
    {
        key: 'calendar',
        icon: React.createElement(CalendarOutlined),
        label: <Link to="calendar">Kalendarz</Link>
    },
    {
        key: 'logs',
        icon: React.createElement(EyeOutlined),
        label: <Link to="logs">Logi</Link>
    }
]

const AdminLayout = () => {
    dayjs.locale('pl')
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
        weekStart: 1
    })

    const {user, group} = useAuthContext()
    const location = useLocation()
    const {api, contextHolder} = useNotificationContext()
    const {logout} = useLogout()

    const menuToAdd = ["other_firm", "reservation_viewer", "reservation_editor"].includes(group) ? [] : restMenu

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
            >
                <div className="demo-logo-vertical">
                    <img src={`${process.env.PUBLIC_URL}/lasertag-logo-white.svg`}/>
                </div>

                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={[location.pathname.split("/")[2] || 'mainpage']}
                    items={[
                        {
                            key: 'mainpage',
                            icon: React.createElement(LineChartOutlined),
                            label: <NavLink to="">Strona główna</NavLink>
                        },
                        {
                            key: 'reservations',
                            icon: React.createElement(OrderedListOutlined),
                            label: <NavLink to="reservations">Rezerwacje</NavLink>
                        },
                        ...menuToAdd
                    ]}
                />
            </Sider>
            <Layout>
                {contextHolder}

                <Header style={{padding: 0, background: "#032244", color: "white"}}>
                    <Flex justify="end" align="center"
                          style={{height: "100%", marginLeft: 25, marginRight: 25}}>
                        <Space>
                            {React.createElement(UserOutlined)}
                            <Text style={{color: "white"}}>{Cookies.get('username')}</Text>
                            <Button size="small" ghost style={{marginLeft: 10}} onClick={logout}>Wyloguj</Button>
                        </Space>
                    </Flex>
                </Header>
                <Content style={{margin: '24px 16px 100px', overflow: 'auto'}}>
                    <div style={{padding: 24, minHeight: 200}}> {/*, background: "white"*/}
                        <GroupsContextProvider>
                            <Outlet/>
                        </GroupsContextProvider>
                    </div>
                </Content>
                {/*<Footer style={{textAlign: 'center'}}>2023</Footer>*/}
            </Layout>
        </Layout>
    )
}

export default AdminLayout