import {Button, Flex, Space, Table, Typography, Popconfirm} from 'antd';
import {NavLink, useLoaderData, useNavigate} from "react-router-dom"
import {useState} from "react";
import {useMobileBreakpoint} from "../../../hooks/useMobileBreakpoint";
import {useNotificationContext} from "../../../hooks/useNotificationContext";

const {Title} = Typography;
export default function Events() {
    const data = useLoaderData()
    const {isMobile} = useMobileBreakpoint()
    const navigate = useNavigate()
    const {api} = useNotificationContext()

    const [dataSource, setDataSource] = useState(data.map(event => ({
        key: event.id,
        name: event.name,
        room_campfire_reservations: event.room_campfire_reservations ? "Tak" : "Nie",
        system_reservations: event.system_reservations ? "Tak" : "Nie",
        display_order: event.display_order
    })))

    const columns = [
        {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left'
        },
        {
            title: 'Rezerwacja sali/ogniska',
            dataIndex: 'room_campfire_reservations',
            key: 'room_campfire_reservations'
        },
        {
            title: 'Rezerwacja przez system',
            dataIndex: 'system_reservations',
            key: 'system_reservations'
        },
        {
            title: 'Pozycja wyświetlania',
            dataIndex: 'display_order',
            key: 'display_order'
        },
        {
            title: 'Akcje',
            key: 'actions',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Button>
                        <NavLink to={`edit/${record.key.toString()}`}>Edytuj</NavLink>
                    </Button>
                    <Popconfirm
                        placement="bottomRight"
                        title={"Usunięcie wydarzenia"}
                        description={`Czy chcesz usunąć wydarzenie '${record.name}'?`}
                        onConfirm={() => deleteEvent(record.key, record.name)}
                        okText="Tak"
                        cancelText="Nie"
                    >
                        <Button danger>Usuń</Button>
                    </Popconfirm>
                </Space>
            )
        }
    ]

    const deleteEvent = async (eventId, name) => {
        const response = await fetch(`/api/v1/events/${eventId}`, {
            credentials: 'include',
            method: "DELETE",
            headers: {
                'Content-Type': 'applications/json'
            }
        })

        if (!response.ok) {
            const error = response.status === 500 ? "Nieznany błąd!" : await response.json()

            api['error']({
                message: 'Błąd',
                description: error
            })

        } else {
            api['success']({
                message: 'Sukces',
                description: `Usunięto wydarzenie ${name}.`
            })

            setDataSource(dataSource.filter((event) => event.key !== eventId))
        }
    }

    return (
        <div className="events">
            <Flex justify="space-between" align="center">
                <Title level={3}>Wydarzenia</Title>
                <Button type="primary" onClick={() => navigate('/admin/events/create')}>Utwórz</Button>
            </Flex>
            <Table dataSource={dataSource} columns={isMobile ? columns.filter(c => c.key === "name" || c.key === "actions") : columns}/>
        </div>
    )
}


export const eventsLoader = async () => {
    const response = await fetch('/api/v1/events/', {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        throw Error('Could not fetch the list of events')
    }

    return await response.json()
}
