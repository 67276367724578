import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";

const ProtectedRoute = ({ children, accessBy, redirectTo }) => {
    const { user } = useAuthContext()

    if (accessBy === "non-authenticated") {
        if (!user) {
            return children;
        }
    } else if (accessBy === "authenticated") {
        console.log("aaaaa authenticated")
        if (user) {
            return children;
        }
    }

    console.log("RE", redirectTo)

    return <Navigate to = {redirectTo ? redirectTo : "/"} > < /Navigate>
}

export default ProtectedRoute