import React, {memo, useCallback, useEffect, useState} from 'react';
import {Button, Card, Col, DatePicker, Form, Row, Select, Space, Typography} from "antd";

import Meta from "antd/es/card/Meta";
import {useReservationFormContext} from "../../../hooks/useReservationFormContext";
import dayjs from "dayjs";
import updateLocale from 'dayjs/plugin/updateLocale'
import ReservationDatePicker from "../../../pages/admin/reservations/ReservationDatePicker";

const {Text, Title} = Typography;

const DateTimePage = ({prevPage, nextPage}) => {
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
        weekStart: 1
    })

    const {form} = useReservationFormContext()
    const params = '?' + new URLSearchParams(form.getFieldsValue(true)).toString()

    const [error, setError] = useState("")
    const [trigger, setTrigger] = useState(false)
    const [hours, setHours] = useState([])

    const loadHours = (selectedDate) => {
        if (!selectedDate) {
            setHours([])
            return
        }

        fetch(`/api/v1/free-hours${params}&date=${selectedDate}`)
        .then(response => {
            if (response.status === 200) {
                response.json().then(result => {
                    setHours(result)
                    setError("")
                })
            } else {
                response.json().then(result => {
                    setError(result.detail)
                    setHours([])
                })
            }
        })
    }

    const [selectedDate, setSelectedDate] = useState(form.getFieldValue('date'))
    const dataLoader = useCallback(loadHours, [selectedDate])

    const onDateChange = (date, dateString) => {
        setSelectedDate(dateString)

        form.setFieldValue('date', dateString)
        form.setFieldValue('hour', null)

        dataLoader(dateString)
    }

    return (
        <Row justify="center" align="middle" style={{marginTop: 20}}>
            <Col xs={24} sm={24} md={24} lg={12}>
                <Card>

                    <Form layout="vertical" form={form} onFinish={() => nextPage()}>

                        <Meta
                            title="Wybierz dzień"
                            style={{marginBottom: '1vh'}}
                        />

                        <Form.Item
                            // name="_date"
                            rules={[{required: true, message: "Wybierz dzień!"}]}
                        >
                            <ReservationDatePicker
                                dependence={["event_id", "game_id", "party_type", "normal_tickets", "reduced_tickets"]}
                                form={form}
                                trigger={trigger}
                                onChange={onDateChange}
                                defaultValue={selectedDate ? dayjs(selectedDate, 'YYYY-MM-DD') : null}
                            />
                        </Form.Item>

                        {error ? <Text type="danger">{error}</Text> : <></>}

                        <Meta
                            title="Wybierz godzinę"
                            style={{marginBottom: '1vh', marginTop: '2vh'}}
                        />
                        <Form.Item
                            name="hour"
                            rules={[{required: true, message: "Wybierz godzinę!"}]}
                        >
                            <Select
                                style={{
                                    width: 140,
                                }}
                                loading={!hours}
                                disabled={!hours}
                                options={hours && hours.map(hour => ({value: hour, label: hour}))}
                            />
                        </Form.Item>

                        <Row justify="end" style={{marginTop: '2vh'}}>
                            <Col>
                                <Space>
                                    <Button onClick={() => prevPage()}>WRÓĆ</Button>
                                    <Button type="primary" htmlType="submit" disabled={error}>ZATWIERDŹ</Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>

                </Card>
            </Col>
        </Row>
    )
}

export default memo(DateTimePage);