import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {Col, Flex, Layout, Popover, Row, Select, Space, Steps} from 'antd';

import {useNotificationContext} from "../hooks/useNotificationContext";
import {useReservationFormContext} from "../hooks/useReservationFormContext";
import {useLoaderData} from "react-router-dom";
import EventPage from "../components/reservation/event/EventPage";
import TicketsPage from "../components/reservation/tickets/TicketsPage";
import GamePage from "../components/reservation/game/GamePage";
import DateTimePage from "../components/reservation/date/DateTimePage";
import ClientInfoPage from "../components/reservation/info/ClientInfoPage";
import dayjs from "dayjs";
require('dayjs/locale/pl')

// import dayjs from 'dayjs';

const {Option} = Select;
const {Header, Content} = Layout;


const customDot = (dot, {status, index}) => (
    <Popover
        content={
            <span>
        step {index} status: {status}
      </span>
        }
    >
        {dot}
    </Popover>
);

const ReservationLayout = () => {
    dayjs.locale('pl')

    const data = useLoaderData()
    const {api, contextHolder} = useNotificationContext()
    const {form, setSelectedGame, setLoadedGamesParties, setSelectedEvent} = useReservationFormContext()
    const [page, setPage] = useState(0)

    const getCurrentEvent = () => {
        return data.find(event => event.id === form.getFieldValue("event_id"))
    }

    const nextPage = useCallback(() => {
        setPage(page + 1)

        // Clear selected game and list of games and parties
        if (page === 1) {
            setSelectedEvent(getCurrentEvent())
            setSelectedGame(null)
            setLoadedGamesParties(null)
        }
    })

    const prevPage = useCallback(() => {
        if (page === 0) {
            return
        }

        setPage(page - 1)
    })

    const render = () => {
        switch (page) {
            case 0:
                return <EventPage events={data} nextPage={nextPage}/>
            case 1:
                return <TicketsPage prevPage={prevPage} nextPage={nextPage}/>
            case 2:
                return <GamePage event={data.filter(event => event.id === form.getFieldValue('event_id'))[0]}
                                 prevPage={prevPage} nextPage={nextPage}/>
            case 3:
                return <DateTimePage prevPage={prevPage} nextPage={nextPage}/>

            case 4:
                return <ClientInfoPage
                    prevPage={prevPage}
                    event={data.filter(event => event.id === form.getFieldValue('event_id'))[0]}
                />
        }
    }

    const pageContent = useMemo(render, [page])

    useEffect(() => {
        console.log("layout render")
    }, [])

    return (
        <Layout style={{minHeight: '100vh'}}>
            {contextHolder}

            <Header style={{padding: 0, background: "#032244"}}>
                <Flex justify="space-between" align="center" style={{height: "100%", marginLeft: 25, marginRight: 25}}>
                        <img alt="Logo" src={`${process.env.PUBLIC_URL}/lasertag-logo-white.svg`} style={{maxWidth: "120px"}}/>

                            <Select style={{width: 110}} defaultValue={['pl']}>
                                <Option value="pl" label="Polski">
                                    <Space>
                                        <span role="img" aria-label="pl">{"\u{1F1F5}\u{1F1F1}"}</span>Polski
                                    </Space>
                                </Option>
                                {/*<Option value="en" label="English">*/}
                                {/*    <Space>*/}
                                {/*        <span role="img" aria-label="en">{"\u{1F1EC}\u{1F1E7}"}</span>English*/}
                                {/*    </Space>*/}
                                {/*</Option>*/}
                            </Select>
                </Flex>
            </Header>
            <Content style={{margin: '24px 16px 100px', overflow: 'auto'}}>
                <Row type="flex" justify="space-around" align="middle" style={{marginTop: '3vh'}}>
                    <Col xs={22} sm={16} md={12} lg={16} xl={16} xxl={12}>
                        <Row type="flex" justify="center" align="middle" style={{marginBottom: '30px'}}>
                            <Col>
                                <Steps
                                    current={page}
                                    progressDot={customDot}
                                    items={[
                                        {
                                            title: 'Rodzaj imprezy'
                                        },
                                        {
                                            title: 'Liczba osób'
                                        },
                                        {
                                            title: 'Rodzaj gry'
                                        },
                                        {
                                            title: 'Data'
                                        },
                                        {
                                            title: 'Dane kontaktowe'
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>

                        {/*{"BRRR" + form.getFieldValue('reduced_tickets')}*/}
                        {pageContent}
                        {/*{page === 3 && <DateTimePage prevPage={null} nextPage={null}/>}*/}

                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default ReservationLayout


export const publicEventsLoader = async () => {
    const response = await fetch('/api/v1/public-events', {
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        throw Error('Could not fetch basic informations!')
    }

    return await response.json()
}
