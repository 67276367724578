import {Button, DatePicker, Flex, Form, Input, Space, Typography} from 'antd'
import {useLoaderData, useNavigate, useParams} from "react-router-dom"
import {useNotificationContext} from "../../../hooks/useNotificationContext";
import {useMemo} from "react";
import UserInfoForm from "../../../components/shared/UserInfoForm";
import dayjs from "dayjs";
import {convertDates, convertDatesToString, convertErrorInfo} from "../../../utils/utils";
import PartyPicker from "./components/PartyPicker";
import BaseReservationForm from "./components/BaseReservationForm";

const {TextArea} = Input;
const {Title, Paragraph, Text} = Typography;


export default function PartyReservationDetails() {
    const navigate = useNavigate()
    const {api} = useNotificationContext()

    const {id} = useParams()
    const [form] = Form.useForm()
    const reservation = useLoaderData()

    const hours = useMemo(() => [...Array(4 * 24).keys()]
            .map(i => dayjs('00:00', 'HH:mm')
                .add(15 * i, 'minute')
                .format('HH:mm')),
        [])

    const onFinish = async (values) => {
        if (values.user_phone) {
            values['user_phone'] = values['user_phone'].toString()
        }

        const response = await fetch(`/api/v1/reservations/party/${id || ''}`, {
            method: reservation ? "PATCH" : "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(convertDatesToString(values, ["date", "YYYY-MM-DD"]))
        })

        if (response.ok) {
            api['success']({
                message: 'Sukces',
                description: reservation ? `Pomyślnie zaktualizowano dane rezerwacji.` : `Pomyślnie utworzono rezerwację.`
            })
            navigate('/admin/reservations/')
        } else {
            response.json().then(data => api['error']({
                message: 'Błąd',
                description: convertErrorInfo(data)
            }))
        }
    }

    const disabledDate = (current) => {
        return current && current <= dayjs().add(-1, 'day')
    }

    return (
        <div className="reservation-details">
            <Title level={3}>{reservation ? "Edycja rezerwacji" : "Nowa rezerwacja"}</Title>

            <div>

                <Form
                    form={form}
                    name="reservationDetails"
                    initialValues={convertDates(reservation, ["date", "YYYY-MM-DD"])}
                    layout="vertical"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: 500,
                        padding: 24,
                        background: "white"
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        name="party_type"
                        label="Typ"
                        rules={[{require: true, message: "Wybierz typ!"}]}
                    >
                        <PartyPicker/>
                    </Form.Item>

                    <Form.Item
                        label="Data rezerwacji"
                        name="date"
                        rules={[{required: true, message: "Wybierz datę!"}]}
                    >
                        <DatePicker
                            disabledDate={disabledDate}
                        />
                    </Form.Item>

                    <BaseReservationForm
                        form={form}
                    />

                    <UserInfoForm
                        form={form}
                        required={false}
                        selectedParty={null}
                        initialValues={reservation}
                        showDescriptions={false}
                    />

                    <Form.Item
                        style={{marginBottom: 0}}>
                        <Flex justify="end" align="center">
                            <Space align="end">
                                <Button type="default" onClick={() => navigate('/admin/reservations')}>
                                    Anuluj
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    {reservation ? "Zapisz" : "Utwórz"}
                                </Button>
                            </Space>
                        </Flex>
                    </Form.Item>

                </Form>

            </div>

        </div>
    )
}


export const partyReservationDetailsLoader = async ({params}) => {
    const {id} = params

    if (!id) {
        return null
    }

    const response = await fetch(`/api/v1/reservations/party/${id}`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        throw Error('Nie można pobrać danych tej rezerwacji.')
    }

    return await response.json()
}