import {useState} from "react";

import {Checkbox, Form, Input, InputNumber, Space, Typography} from "antd";
import {PARKING_PHONE} from "../../config";

const {TextArea} = Input;
const {Text} = Typography;

export default function UserInfoForm({required = true, selectedParty = null, showDescriptions = true}) {
    const [invoice, setInvoice] = useState()

    // TODO: zmienić min i max len w input na rules

    const renderParkingInfo = () => {
        if (PARKING_PHONE) {
            return
        }

        if (!showDescriptions) {
            return;
        }

        return <>
            <Text type="secondary">Podaj numer rejestracyjny samochodu, który zaparkować może
                pod fortem
                (maks 1 samochód)</Text><br/>
            <Text type="secondary">Zasady parkowania: <a>Link</a></Text><br/>
        </>
    }

    return <>
        <Form.Item
            name="user_name"
            label="Imię"
            rules={[{required: required, message: 'Wprowadź imię!'}]}>
            <Input/>
        </Form.Item>
        <Form.Item
            name="user_surname" label="Nazwisko"
            rules={[{required: required, message: 'Wprowadź nazwisko!'}]}>
            <Input/>
        </Form.Item>
        <Form.Item
            name="user_email" label="Email"
            rules={[{type: "email", message: "Niepoprawny email!"}, {
                required: required,
                message: 'Wprowadź email!'
            }]}>
            <Input/>
        </Form.Item>
        <Form.Item
            name="user_phone" label="Telefon"
            rules={[{required: required, message: 'Wprowadź nr telefonu!'}]}>
            <InputNumber
                style={{width: 150}}
                maxLength="11"
                formatter={(value) => (value.match(/.{1,3}/g) || []).join(" ")}
            />
        </Form.Item>
        <Form.Item
            name="is_invoice"
            valuePropName="checked">
            <Checkbox
                onChange={(e) => setInvoice(e.target.checked)}
            >
                Potrzebuję fakturę VAT
            </Checkbox>
        </Form.Item>

        <div style={{display: invoice ? "block" : "none"}}>
            <Form.Item
                name="company_nip"
                label="NIP"
                rules={[{required: invoice, message: 'Wprowadź nr NIP!'}]}
            >
                <Input
                    minLength={10}
                    maxLength={10}
                />
            </Form.Item>
            <Form.Item
                name="company_name"
                label="Firma"
                rules={[{required: invoice, message: 'Wprowadź nazwę firmy!'}]}
            >
                <Input minLength={3}/>
            </Form.Item>
            <Form.Item
                name="address"
                label="Ulica i numer"
                rules={[{required: invoice, message: 'Wprowadź ulicę!'}]}
            >
                <Input
                    minLength={3}
                />
            </Form.Item>
            <Space align="center">
                <Form.Item
                    name="zip_code"
                    label="Kod pocztowy"
                    rules={[{required: invoice, message: 'Wprowadź kod pocztowy!'}]}
                >
                    <Input minLength={3}/>
                </Form.Item>
                <Form.Item
                    name="city"
                    label="Miasto"
                    rules={[{required: invoice, message: 'Wprowadź miasto!'}]}
                >
                    <Input minLength={3}/>
                </Form.Item>
            </Space>
        </div>

        <Form.Item
            name="user_comment"
            label="Dodatkowe uwagi"
        >
            <TextArea rows={2}/>
        </Form.Item>

        <Text>{PARKING_PHONE ? "Numer telefonu" : "Numer rejestracyjny samochodu"}</Text><br/>
        {renderParkingInfo()}
        <Form.Item
            name="parking_data"
            disabled={!selectedParty}
        >
            {PARKING_PHONE ?
                <InputNumber
                    style={{width: 150}}
                    maxLength="11"
                    formatter={(value) => (value.match(/.{1,3}/g) || []).join(" ")}
                />
                :
                <Input/>
            }
        </Form.Item>


    </>

}
