import {Button, Flex, Space, Table, Typography, Popconfirm} from 'antd';
import {NavLink, useLoaderData, useNavigate} from "react-router-dom"
import {useState} from "react";
import {useMobileBreakpoint} from "../../../hooks/useMobileBreakpoint";
import {useNotificationContext} from "../../../hooks/useNotificationContext";

const {Title} = Typography;

export default function Users() {
    const data = useLoaderData()
    const {isMobile} = useMobileBreakpoint()
    const navigate = useNavigate()
    const {api} = useNotificationContext()

    const [dataSource, setDataSource] = useState(data.users.map(user => ({
        'key': user.id,
        'username': user.username,
        'group': data.groups[user.group],
        'email_notifications': user.email_notifications ? "Tak" : "Nie"
    })))

    const columns = [
        {
            title: 'Nazwa użytkownika',
            dataIndex: 'username',
            key: 'username',
            fixed: 'left'
        },
        {
            title: 'Grupa',
            dataIndex: 'group',
            key: 'group',
            render: (_, record) => (
                <Space size="middle">
                    {record.group}
                </Space>
            )
        },
        {
            title: 'Akcje',
            key: 'actions',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Button>
                        <NavLink to={`edit/${record.key.toString()}`}>Edytuj</NavLink>
                    </Button>
                    <Popconfirm
                        placement="bottomRight"
                        title={"Usunięcie użytkownika"}
                        description={`Czy chcesz usunąć użytkownika '${record.username}'?`}
                        onConfirm={() => deleteUser(record.key, record.username)}
                        okText="Tak"
                        cancelText="Nie"
                    >
                        <Button danger>Usuń</Button>
                    </Popconfirm>
                </Space>
            )
        }
    ]

    const deleteUser = async (userId, username) => {
        const response = await fetch(`/api/v1/users/${userId}`, {
            credentials: 'include',
            method: "DELETE",
            headers: {
                'Content-Type': 'applications/json'
            }
        })

        if (!response.ok) {
            const error = response.status === 500 ? "Nieznany błąd!" : await response.json()

            api['error']({
                message: 'Błąd',
                description: error
            })

        } else {
            api['success']({
                message: 'Sukces',
                description: `Usunięto użytkownika ${username}.`
            })

            setDataSource(dataSource.filter((user) => user.key !== userId))
        }
    }

    return (
        <div className="users">
            <Flex justify="space-between" align="center">
                <Title level={3}>Użytkownicy</Title>
                <Button type="primary" onClick={() => navigate('/admin/users/create')}>Utwórz</Button>
            </Flex>
            <Table dataSource={dataSource} columns={isMobile ? columns.filter(c => c.key !== "group") : columns}/>
        </div>
    )
}


export const usersLoader = async () => {
    const response = await fetch('/api/v1/users/', {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        throw Error('Could not fetch the list of users')
    }

    const groupResponse = await fetch('/api/v1/groups/', {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!groupResponse.ok) {
        throw Error('Could not fetch the list of groups')
    }

    return {
        users: await response.json(),
        groups: await groupResponse.json()
    }
}
