import {Button, Flex, Form, Input, Popconfirm, Space, Table, Typography} from 'antd';
import {NavLink, redirect, useLoaderData, useNavigate} from "react-router-dom"
import {useState} from "react";
import {useMobileBreakpoint} from "../../../hooks/useMobileBreakpoint";
import {useNotificationContext} from "../../../hooks/useNotificationContext";

const {Title, Text} = Typography;

const consumeData = (data) => {
    return data.map(voucher => ({
        key: voucher.id,
        code: voucher.code,
        is_voucher: voucher.is_voucher,
        value: voucher.value
    }))
}

export default function Vouchers() {
    const data = useLoaderData()
    const {isMobile} = useMobileBreakpoint()
    const navigate = useNavigate()
    const {api} = useNotificationContext()
    const [dataSource, setDataSource] = useState(consumeData(data))
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            title: 'Kod',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Typ',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => (
                <Space size="middle">
                    {record.is_voucher ? "Voucher" : "Kod rabatowy"}
                </Space>
            )
        },
        {
            title: 'Wartość',
            dataIndex: 'value',
            key: 'value',
            render: (_, record) => (
                <Text>{record.value}{record.is_voucher ? "zł" : "%"}</Text>
            )
        },
        {
            title: 'Akcje',
            key: 'actions',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Button>
                        <NavLink to={`edit/${record.key.toString()}`}>Edytuj</NavLink>
                    </Button>
                    <Popconfirm
                        placement="bottomRight"
                        title={"Usunięcie vouchera"}
                        description={`Czy chcesz usunąć voucher '${record.code}'?`}
                        onConfirm={() => deleteVoucher(record.key, record.code)}
                        okText="Tak"
                        cancelText="Nie"
                    >
                        <Button danger>Usuń</Button>
                    </Popconfirm>
                </Space>
            )
        }
    ]

    const deleteVoucher = async (voucherId, code) => {
        const response = await fetch(`/api/v1/vouchers/${voucherId}`, {
            credentials: 'include',
            method: "DELETE",
            headers: {
                'Content-Type': 'applications/json'
            }
        })

        if (!response.ok) {
            const error = response.status === 500 ? "Nieznany błąd!" : await response.json()

            api['error']({
                message: 'Błąd',
                description: error.detail
            })

        } else {
            api['success']({
                message: 'Sukces',
                description: `Usunięto voucher '${code}'.`
            })

            setDataSource(dataSource.filter((voucher) => voucher.key !== voucherId))
        }
    }

    const applyFilters = async (values) => {
        setLoading(true)

        try {
            const data = await vouchersLoader(null, values)
            setDataSource(consumeData(data))
        } catch (e) {
            api['error']({
                title: "Błąd wyszukiwania!"
            })
        }

        setLoading(false)
    }

    return (
        <div className="vouchers">
            <Flex justify="space-between" align="center">
                <Title level={3}>Vouchery</Title>
                <Button type="primary" onClick={() => navigate('/admin/vouchers/create')}>Utwórz</Button>
            </Flex>

            <Space
                direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                    marginBottom: 20
                }}
            >
                <Title level={4}>Filtry</Title>
                <Form
                    layout="vertical"
                    initialValues={{code: ""}}
                    labelCol={{
                        span: 20,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={applyFilters}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Kod"
                        name="code"
                        layout="vertical"
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 6,
                        }}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        style={{marginBottom: 0}}>
                        <Space align="end">
                            <Button htmlType="submit" loading={loading}>
                                Filtruj
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Space>

            <Table
                dataSource={dataSource}
                columns={columns}
            />
        </div>
    )
}
export const vouchersLoader = async (_, filters = null) => {
    const params = filters ? ('?' + new URLSearchParams(filters).toString()) : ""

    const response = await fetch(`/api/v1/vouchers/${params}`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        if (response.status === 401) {
            return redirect("/auth/logout")
        }

        throw Error('Could not fetch the list of vouchers!')
    }

    console.log("done")

    return await response.json()
}
