import {useEffect, useState} from 'react'
import {Grid} from 'antd';

const {useBreakpoint} = Grid;

export const useMobileBreakpoint = () => {
    const screens = useBreakpoint()
    const [isMobile, setMobile] = useState(null)

    useEffect(() => {
        const firstBreakPoint = Object.entries(screens).filter((screen) => !!screen[1])[0]
        if (!firstBreakPoint) {
            return
        }

        if (firstBreakPoint[0] === "xs") {
            setMobile(true)
        } else {
            setMobile(false)
        }

    }, [screens, setMobile])

    return {isMobile}
}