import {createContext, useEffect, useReducer, useState} from 'react'
import Cookies from 'js-cookie';

export const AuthContext = createContext()

export const AuthContextProvider = ({children}) => {
    const [user, setUser] = useState(Cookies.get('username'))
    const [group, setGroup] = useState(Cookies.get('group'))

    useEffect(() => {
        const user = Cookies.get('username')

        if (user) {
            setUser(user)
        }

        const group = Cookies.get('group')
        if (group) {
            setGroup(group)
        }
    }, [])

    return (
        <AuthContext.Provider value={{user, setUser, group, setGroup}}>
            {children}
        </AuthContext.Provider>
    )

}