import {useEffect, useState} from 'react';

const useFetch = (url, dataConsumer = null, includeCredentials = false) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!url) {
            return;
        }

        const abortCont = new AbortController();

        setTimeout(() => {
            fetch(url, {
                signal: abortCont.signal,
                credentials: includeCredentials ? 'include' : 'same-origin',
                headers: {
                    'Content-Type': 'applications/json'
                }
            })
                .then(res => {
                    if (!res.ok && res.status !== 400 && res.status !== 410) { // error coming back from server
                        throw Error('could not fetch the data for that resource');
                    }

                    return res.json().then(data => {
                        setIsPending(false);

                        if (res.ok) {
                            setData(data);
                            setError(null);
                        } else {
                            setError(data);
                            // setData(null);
                        }

                        if (dataConsumer) {
                            dataConsumer(res.status, data);
                        }

                    })
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        console.log('fetch aborted')
                    } else {
                        // auto catches network / connection error
                        setIsPending(false);
                        setError(err.message);
                        if (dataConsumer) {
                            dataConsumer(0, {})
                        }
                    }
                })
        }, 0);

        // abort the fetch
        return () => abortCont.abort();
    }, [url])

    return {data, isPending, error, setError};
}

export default useFetch;