import { useState } from 'react'
import { useAuthContext } from './useAuthContext'
import {Navigate} from "react-router-dom";
import Cookies from 'js-cookie';

export const useLogin = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const { setUser, setGroup } = useAuthContext()

    const login = async (username, password) => {
        setIsLoading(true)
        setError(null)

        const formData = new FormData()
        formData.append('username', username)
        formData.append('password', password)

        const response = await fetch('/api/v1/auth/login', {
            credentials: 'include',
            method: 'POST',
            body: formData
        })

        if (!response.ok) {
            setIsLoading(false)

            switch (response.status) {
                case 401:
                    setError(`Incorrect password or username`)
                    return

                default:
                    setError(`Unexpected Error (${response.status})! ${response.detail}`)
                    return
            }
        }

        if (response.ok) {
            // update loading state
            setIsLoading(false)

            setUser(Cookies.get('username'))
            setGroup(Cookies.get('group'))
        }
    }

    return { login, isLoading, error }
}