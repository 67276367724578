// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  position:relative;
  margin-left:auto;
  margin-right:auto;
  padding-right:15px;
  padding-left:15px
}
@media (min-width: 476px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 768px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 992px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 1200px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 476px){
  .container{
    width:100%
  }
}
@media (min-width: 768px){
  .container{
    width:720px;
    max-width:100%
  }
}
@media (min-width: 992px){
  .container{
    width:960px;
    max-width:100%
  }
}
@media (min-width: 1200px){
  .container{
    width:1140px;
    max-width:100%
  }
}
@media (min-width: 1400px){
  .container{
    width:1340px;
    max-width:100%
  }
}

.reservation_info {
  padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB;AACF;AACA;EACE;IACE,kBAAkB;IAClB;EACF;AACF;AACA;EACE;IACE,kBAAkB;IAClB;EACF;AACF;AACA;EACE;IACE,kBAAkB;IAClB;EACF;AACF;AACA;EACE;IACE,kBAAkB;IAClB;EACF;AACF;AACA;EACE;IACE;EACF;AACF;AACA;EACE;IACE,WAAW;IACX;EACF;AACF;AACA;EACE;IACE,WAAW;IACX;EACF;AACF;AACA;EACE;IACE,YAAY;IACZ;EACF;AACF;AACA;EACE;IACE,YAAY;IACZ;EACF;AACF;;AAEA;EACE,aAAa;AACf","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.container{\n  position:relative;\n  margin-left:auto;\n  margin-right:auto;\n  padding-right:15px;\n  padding-left:15px\n}\n@media (min-width: 476px){\n  .container{\n    padding-right:15px;\n    padding-left:15px\n  }\n}\n@media (min-width: 768px){\n  .container{\n    padding-right:15px;\n    padding-left:15px\n  }\n}\n@media (min-width: 992px){\n  .container{\n    padding-right:15px;\n    padding-left:15px\n  }\n}\n@media (min-width: 1200px){\n  .container{\n    padding-right:15px;\n    padding-left:15px\n  }\n}\n@media (min-width: 476px){\n  .container{\n    width:100%\n  }\n}\n@media (min-width: 768px){\n  .container{\n    width:720px;\n    max-width:100%\n  }\n}\n@media (min-width: 992px){\n  .container{\n    width:960px;\n    max-width:100%\n  }\n}\n@media (min-width: 1200px){\n  .container{\n    width:1140px;\n    max-width:100%\n  }\n}\n@media (min-width: 1400px){\n  .container{\n    width:1340px;\n    max-width:100%\n  }\n}\n\n.reservation_info {\n  padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
