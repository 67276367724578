import {Button, Flex, Form, Input, InputNumber, Select, Space, Switch, Typography} from 'antd'
import {useLoaderData, useNavigate, useParams} from "react-router-dom"
import {useNotificationContext} from "../../../hooks/useNotificationContext";

const {Title, Paragraph} = Typography;


export default function EventDetails() {
    const [form] = Form.useForm()
    const {id} = useParams()
    const {event, games} = useLoaderData()
    const navigate = useNavigate()
    const {api} = useNotificationContext()

    const onFinish = async (values) => {
        const response = await fetch(`/api/v1/events/${id ? id : ''}`, {
            method: event ? "PATCH" : "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })

        if (response.ok) {
            api['success']({
                message: 'Sukces',
                description: event ? `Pomyślnie zaktualizowano dane wydarzenia ${values.name}.` : `Pomyślnie utworzono wydarzenie ${values.name}.`
            })
            navigate('/admin/events')
        } else {
            response.json().then(data => api['error']({
                message: 'Błąd',
                description: data.detail.map((error) => (
                    <div>
                        <Paragraph>{error.loc[1]}: {error.msg}</Paragraph>
                    </div>
                ))
            }))
        }
    }

    return (
        <div className="event-details">
            <Title level={3}>{event ? "Edycja wydarzenia" : "Nowe wydarzenie"}</Title>

            <div>

                <Form
                    form={form}
                    name="eventDetails"
                    initialValues={event}
                    layout="vertical"
                    labelCol={{
                        span: 16,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: 500,
                        padding: 24,
                        background: "white"
                    }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Nazwa"
                        name="name"
                        rules={[
                            {
                                min: 3,
                                message: "Nazwa wydarzenia musi zawierać min 3 znaki"
                            },
                            {
                                required: true,
                                message: 'Wprowadź nazwę wydarzenia!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item label="Opis" name="description">
                        <Input.TextArea/>
                    </Form.Item>

                    <Form.Item
                        name="photo"
                        label="Grafika (URL)"
                        rules={[
                            {
                                type: 'url',
                                warningOnly: true,
                                message: 'Link do grafiki jest niepoprawny!'
                            }
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item label="Rezerwacja sali/ognisk" name="room_campfire_reservations" valuePropName="checked">
                        <Switch/>
                    </Form.Item>

                    <Form.Item label="Rezerwacja przez system"
                               tooltip="Włącz opcję, aby klient mógł dokonać rezerwację przez aplikację rezerwacji.
                               Jeżeli chcesz by klient dzwonił w celu rezerwacji tego wydarzenia, wyłącz tą opcję."
                               name="system_reservations"
                               valuePropName="checked">
                        <Switch/>
                    </Form.Item>

                    <Form.Item label="Wyświetl wydarzenie"
                               tooltip="Włącz, aby wyświetlić wydarzenie klientom. Jeżli dalej pracujesz nad konfiguracją, pozostaw wyłączony."
                               name="enabled"
                               valuePropName="checked">
                        <Switch/>
                    </Form.Item>

                    <Form.Item
                        name="games_ids"
                        label="Gry"
                    >
                        <Select
                            mode="multiple"
                            placeholder="Wybierz gry"
                            options={games.map(game => ({
                                value: game.id,
                                label: game.name
                            }))}
                        >

                        </Select>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{span: 12}}
                        label="Kolejność wyświetlania"
                        name="display_order"
                    >
                        <InputNumber/>
                    </Form.Item>

                    <Form.Item
                        style={{marginBottom: 0}}>
                        <Flex justify="end" align="center">
                            <Space align="end">
                                <Button type="default" onClick={() => navigate('/admin/events')}>
                                    Anuluj
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    {event ? "Zapisz" : "Utwórz"}
                                </Button>
                            </Space>
                        </Flex>
                    </Form.Item>

                </Form>

            </div>

        </div>
    )
}


export const eventDetailsLoader = async ({params}) => {
    const {id} = params

    const gamesResponse = await fetch('/api/v1/games/', {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!gamesResponse.ok) {
        throw Error('Could not fetch the list of games')
    }

    if (!id) {
        return {
            event: null,
            games: await gamesResponse.json()
        }
    }

    const response = await fetch(`/api/v1/events/${id}`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        throw Error('Nie można pobrać danych tego wydarzenia.')
    }

    return {
        event: await response.json(),
        games: await gamesResponse.json()
    }
}