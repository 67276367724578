import {Card, Col, Flex, Layout, Row, Space, Spin, Typography} from "antd";
import {CheckCircleTwoTone} from '@ant-design/icons';
import Meta from "antd/es/card/Meta";
import React, {useState} from "react";
import useFetch from "../hooks/useFetch";
import {useNavigate, useParams} from "react-router-dom";

const {Text, Title} = Typography;

const Payment = () => {
    const {id} = useParams()
    const navigate = useNavigate()

    const [title, setTitle] = useState(null)
    const [done, setDone] = useState(null)
    const [subtitle, setSubtitle] = useState(null)

    const {data, isPending, error, setError} = useFetch(`/api/v1/payment/reservation/${id}`, (status, data) => {
        console.log(status)
        if (status === 410) {
            window.location.href = `/reservation/${id}/payment-expire`
            return;
        }

        if (status !== 200) {
            return
        }

        setDone(data.done)

        if (data.done) {
            setTitle("Pomyślnie zrealizowano płatność")
            setSubtitle(`Transakcja na kwotę ${data.paid_amount} ${data.paid_currency} wykonano dnia ${data.paid_dt}.`)
        } else {
            setTitle("Przekierowywanie do systemu płatności...")
            setTimeout(() => window.location.href = data.payment_url, 3000)
        }
    })

    return (
        <Layout style={{height: "100vh", backgroundColor: "#032244"}}>

            <Flex align="center" justify="center" style={{marginTop: 40}}>
                <div className="demo-logo-vertical">
                    <img src={`${process.env.PUBLIC_URL}/lasertag-logo-white.svg`} style={{width: "200px"}}/>
                </div>
            </Flex>

            <Row justify="center" align="middle" style={{marginTop: 20}}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card>
                        <Meta
                            style={{marginBottom: '2vh'}}
                        />

                        {isPending &&
                            <Space>
                                <Spin style={{marginRight: 8}}/>
                                <Title level={4} style={{margin: 0}}>Pobieranie informacji o płatności</Title>
                            </Space>
                        }

                        {
                            title &&
                            <Space>
                                {done && <CheckCircleTwoTone twoToneColor="#52c41a"/> }
                                <Title level={4} style={{margin: 0}}>{title}</Title>
                            </Space>
                        }
                        <Title level={5} style={{marginTop: 10}}>{subtitle}</Title>

                        {error && <Title level={4}>Błąd</Title>}

                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}

export default Payment