import {createContext, useState} from 'react'
import {Form} from "antd";

export const ReservationFormContext = createContext()

export const ReservationFormProvider = ({children}) => {
    const [form] = Form.useForm()
    const [selectedGame, setSelectedGame] = useState(null)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [selectedParty, setSelectedParty] = useState(null)
    const [loadedGamesParties, setLoadedGamesParties] = useState(null)
    const [vouchersList, setVouchersList] = useState([])

    return (
        <ReservationFormContext.Provider
            value={{
                form,
                selectedGame,
                setSelectedGame,
                loadedGamesParties,
                setLoadedGamesParties,
                selectedEvent,
                setSelectedEvent,
                vouchersList,
                setVouchersList,
                selectedParty,
                setSelectedParty
            }}
        >
            {children}
        </ReservationFormContext.Provider>
    )
}