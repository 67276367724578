import {useLogin} from "../hooks/useLogin"
import {Button, Card, Col, Flex, Form, Input, Layout, Row, Typography} from "antd";
import Meta from "antd/es/card/Meta";
import {useAuthContext} from "../hooks/useAuthContext";
import {Navigate} from "react-router-dom";
import React from "react";

const {Text} = Typography;

const Login = () => {
    const {login, error, isLoading} = useLogin()

    const handleSubmit = async (values) => {
        await login(values.username, values.password)
    }

    const {user} = useAuthContext()

    if (user) {
        return <Navigate to="/admin"/>
    } else {
        return (
            <Layout style={{height: "100vh", backgroundColor: "#032244"}}>
                <Flex align="center" justify="center" style={{marginTop: 40}}>
                    <div className="demo-logo-vertical">
                        <img src={`${process.env.PUBLIC_URL}/lasertag-logo-white.svg`} style={{width: "200px"}}/>
                    </div>
                </Flex>
                <Row justify="center" align="middle" style={{marginTop: 20}}>
                    <Col xs={24} sm={24} md={12} lg={6}>
                        <Card>
                            <Meta
                                title="Logowanie"
                                style={{marginBottom: '2vh'}}
                            />

                            <Form layout="vertical" onFinish={handleSubmit}>
                                <Form.Item
                                    label="Nazwa użytkownika"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Wprowadź nazwę użytkownika!',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label="Hasło"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Wprowadź hasło!',
                                        },
                                    ]}
                                >
                                    <Input.Password/>
                                </Form.Item>

                                <Button type="primary" htmlType="submit" disabled={isLoading}>Zaloguj się</Button>

                            </Form>

                            {
                                error && <div style={{marginTop: ".5rem"}}><Text type="danger">{error}</Text></div>
                            }

                        </Card>
                    </Col>
                </Row>
            </Layout>
        )
    }
}

export default Login