import React from 'react';
import {Select, Spin, Typography} from "antd";
import useFetch from "../../../../hooks/useFetch";

const {Text} = Typography;

const GamePicker = ({value, onChange, form, setGame, fillTicketPrices = null, ...props}) => {

    const {data, isPending, error} = useFetch('/api/v1/games/', (status, data) => {
            if (value) {
                onSelect(value, data)
            }
        },
        true
    )

    const onGameChange = (newPartyType) => {
        onChange?.(newPartyType)
        const currentGame = onSelect(newPartyType)

        // TODO; przy edycji nadpisuje ceny (bo się załadowała lista gier i ztriggerował onSelect)
        if (fillTicketPrices) {
            fillTicketPrices(currentGame)
        }
    }

    const onSelect = (value, d=data) => {
        const currentGame = d.find(game => game.id === value)
        setGame(currentGame)

        return currentGame
    }

    return <>
        <Select
            value={value}
            placeholder="Wybierz grę"
            onChange={onGameChange}
            options={data && data.map(game => ({
                value: game.id,
                label: game.name
            }))}
        >

        </Select>

        {isPending && < Spin> < /Spin>}

        {error && <Text type="danger"> Błąd: {error} < /Text>}
    </>
}

export default GamePicker;


