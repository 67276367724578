import {Card, Col, Row} from "antd";
import {useReservationFormContext} from "../../../hooks/useReservationFormContext";

const EventCard = ({nextPage, data, showModal}) => {
    const {form} = useReservationFormContext()

    return (
        <Row justify="center" align="middle" style={{marginTop: 20}}>
            <Col xs={24} sm={24} md={24} lg={12}>
                <Card
                    hoverable
                    onClick={() => {
                        if (!data.system_reservations) {
                            showModal()
                            return
                        }

                        form.setFieldValue('event_id', data.id)
                        nextPage()
                    }}
                >
                    <Row align="middle">
                        <Col xs={24} sm={12} md={8}>
                            <img src={data.photo}
                                 style={{width: '100%'}}></img>
                        </Col>
                        <Col xs={24} sm={12} md={16} style={{padding: 20}}>
                            <h3>{data.name}</h3>
                            <p>{data.description}</p>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default EventCard;