import {
    Button,
    Checkbox,
    Col,
    Flex,
    Form,
    Input,
    InputNumber,
    Row,
    Space,
    Switch,
    Typography
} from 'antd'
import {useLoaderData, useNavigate, useParams} from "react-router-dom"
import {useNotificationContext} from "../../../hooks/useNotificationContext";

const {Title, Paragraph} = Typography;

const MONEY_RULES = [
    {
        required: true,
        message: 'Wprowadź cenę!',
    }
]

export default function GameDetails() {
    const [form] = Form.useForm()
    const {id} = useParams()
    const {game} = useLoaderData()
    const navigate = useNavigate()
    const {api} = useNotificationContext()

    const onFinish = async (values) => {
        const response = await fetch(`/api/v1/games/${id ? id : ''}`, {
            method: game ? "PATCH" : "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })

        if (response.ok) {
            api['success']({
                message: 'Sukces',
                description: game ? `Pomyślnie zaktualizowano dane gry ${values.name}.` : `Pomyślnie utworzono grę '${values.name}.`
            })
            navigate('/admin/games')
        } else {
            response.json().then(data => api['error']({
                message: 'Błąd',
                description: data.detail.map((error) => (
                    <div>
                        <Paragraph>{error.loc[1]}: {error.msg}</Paragraph>
                    </div>
                ))
            }))
        }
    }

    return (
        <div className="user-details">
            <Title level={3}>{game ? "Edycja gry" : "Nowa gra"}</Title>

            <div>

                <Form
                    form={form}
                    name="gameDetails"
                    initialValues={game}
                    layout="vertical"
                    labelCol={{
                        span: 20,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: 600,
                        padding: 24,
                        background: "white"
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Nazwa"
                        name="name"
                        rules={[
                            {
                                min: 3,
                                message: "Nazwa gry musi zawierać min 3 znaki"
                            },
                            {
                                required: true,
                                message: 'Wprowadź nazwę gry!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item label="Opis" name="description">
                        <Input.TextArea/>
                    </Form.Item>

                    <Row>
                        <Col span={12}>
                            <Form.Item label="Rezerwacja sali" name="room_reservation" valuePropName="checked"
                                       rules={[]}>
                                <Checkbox/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Rezerwacja ogniska" name="campfire_reservation" valuePropName="checked">
                                <Checkbox/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label="Gra zewnętrzna" name="outside_game" valuePropName="checked">
                        <Switch/>
                    </Form.Item>

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="Czas rezerwacji"
                                name="reservation_time"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Wprowadź czas rezerwacji!',
                                    }]}>
                                <InputNumber
                                    addonAfter="min" min={1}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Czas gry"
                                name="game_time"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Wprowadź czas gry!',
                                    }]}>
                                <InputNumber
                                    addonAfter="min" min={1}/>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Form.Item
                        label="Minimalna liczba biletów do uzyskania zaliczki"
                        name="advance"
                        rules={[
                            {
                                required: true,
                                message: 'Wprowadź liczbę biletów!',
                            }]}>
                        <InputNumber min={0} max={100}/>
                    </Form.Item>

                    <Paragraph>Ceny biletów</Paragraph>

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{span: 12}}
                                label="ulgowy pn-czw"
                                name="costs_tt_reduced"
                                rules={MONEY_RULES}>
                                <InputNumber addonAfter="zł" min={0}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{span: 12}}
                                label="normalny pn-czw"
                                name="costs_tt_normal"
                                rules={MONEY_RULES}>
                                <InputNumber addonAfter="zł" min={0}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{span: 12}}
                                label="ulgowy pt-ndz"
                                name="costs_fs_reduced"
                                rules={MONEY_RULES}>
                                <InputNumber addonAfter="zł" min={0}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{span: 12}}
                                label="normalny pt-ndz"
                                name="costs_fs_normal"
                                rules={MONEY_RULES}>
                                <InputNumber addonAfter="zł" min={0}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="Minimalna liczba uczestników do uzyskania zniżki"
                                name="discount_threshold"
                            >
                                <InputNumber/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Zniżka"
                                name="discount"
                            >
                                <InputNumber addonAfter="%" min={0} max={100}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{span: 12}}
                                label="Minimalny wiek"
                                name="min_age"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Wprowadź minimalny wiek!',
                                    }]}
                            >
                                <InputNumber min={0}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{span: 12}}
                                label="Maksymalny wiek"
                                name="max_age"
                                rules={[

                                ]}
                            >
                                <InputNumber min={1}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{span: 12}}
                                label="Minimalna liczba uczestników"
                                name="min_participants"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Wprowadź minimalną liczbę uczestników!',
                                    }]}
                            >
                                <InputNumber min={0}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{span: 12}}
                                label="Maksymalna liczba uczestników"
                                name="max_participants"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Wprowadź maksymalną liczbę uczestników!',
                                    }]}
                            >
                                <InputNumber min={0}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        wrapperCol={{span: 12}}
                        label="Kolejność wyświetlania"
                        name="display_order"
                    >
                        <InputNumber/>
                    </Form.Item>

                    <Form.Item
                        style={{marginBottom: 0}}>
                        <Flex justify="end" align="center">
                            <Space align="end">
                                <Button type="default" onClick={() => navigate('/admin/games')}>
                                    Anuluj
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    {game ? "Zapisz" : "Utwórz"}
                                </Button>
                            </Space>
                        </Flex>
                    </Form.Item>

                </Form>

            </div>

        </div>
    )
}


export const gameDetailsLoader = async ({params}) => {
    const {id} = params

    if (!id) {
        return {
            game: null,
        }
    }

    const response = await fetch(`/api/v1/games/${id}`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        throw Error('Nie można pobrać danych tej gry.')
    }

    return {
        game: await response.json()
    }
}