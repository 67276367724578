import {Button, Checkbox, DatePicker, Flex, Form, Input, InputNumber, Space, Typography} from 'antd'
import {useLoaderData, useNavigate, useParams} from "react-router-dom"
import {MinusCircleOutlined} from '@ant-design/icons';
import {useNotificationContext} from "../../../hooks/useNotificationContext";
import {useState} from "react";
import UserInfoForm from "../../../components/shared/UserInfoForm";
import dayjs from "dayjs";
import {convertDates, convertDatesToString, convertErrorInfo} from "../../../utils/utils";
import PartyPicker from "./components/PartyPicker";
import BaseReservationForm from "./components/BaseReservationForm";
import GamePicker from "./components/GamePicker";
import useFetch from "../../../hooks/useFetch";
import Summary from "./components/Summary";
import {useReservationFormContext} from "../../../hooks/useReservationFormContext";

const {TextArea} = Input;
const {Title, Paragraph, Text} = Typography;
export default function CustomReservationDetails() {
    const navigate = useNavigate()
    const {api} = useNotificationContext()
    const {form, setSelectedGame, setSelectedParty, selectedGame} = useReservationFormContext()

    const {id} = useParams()
    const reservation = useLoaderData()

    const [trigger, setTrigger] = useState(false)

    const [addGame, setAddGame] = useState(reservation?.game)
    const [addParty, setAddParty] = useState(reservation?.party)

    const [currentDate, setCurrentDate] = useState(reservation?.date)

    const {data, isPending, error} = useFetch(`/api/v1/calendar/`)
    const refresh = () => {
        setTrigger(!trigger)
        console.log("REFRESH")
    }
    const onFinish = async (values) => {
        console.log(values)
        values['payment'] = form.getFieldValue('is_full_payment') ? form.getFieldValue('cost') : form.getFieldValue('advance')
        values['test'] = 1
        console.log(values)

        if (!addGame && values.game) {
            delete values.game
        }

        if (!addParty && values.party) {
            delete values.party
        }

        const response = await fetch(`/api/v1/reservations/custom/${id || ''}`, {
            method: reservation ? "PATCH" : "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(convertDatesToString(values, ["date", "YYYY-MM-DD"]))
        })

        if (response.ok) {
            api['success']({
                message: 'Sukces',
                description: reservation ? `Pomyślnie zaktualizowano dane rezerwacji.` : `Pomyślnie utworzono rezerwację.`
            })
            navigate('/admin/reservations/')
        } else {
            response.json().then(data => api['error']({
                message: 'Błąd',
                description: convertErrorInfo(data)
            }))
        }
    }

    // dodac pole jaka to rezerwacja (custom/normal)
    // dokończyć wyliczanie kosztów custom


    // dokonczyc custom form
    // i18n
    // modal z dodaniem vouchera
    // ukrycie zakładek dla konta drugiego podmiotu
    // ikony w menu

    const disabledDate = (current) => {
        return current && current <= dayjs().add(-1, 'day')
    }

    const onDateChange = (date, dateString) => {
        setCurrentDate(dateString)
        if (selectedGame) {
            fillTicketPrices(selectedGame)
        }

        setTimeout(() => {
            let error = null

            if (dateString) {
                if (!data.week_days[date.weekday()].enabled) {
                    error = ["Ten dzień jest dniem niepracującym."]
                } else if (data.disabled_dates.includes(currentDate)) {
                    error = ["Ten dzień jest wyłączony."]
                }
            }

            form.setFields([
                {
                    name: "date",
                    warnings: error
                }
            ])
        }, 1)

    }

    const fillTicketPrices = (game) => {
        const MT = [0, 1, 2, 3]  // pon -
        const weekDay = dayjs(currentDate, "YYYY-MM-DD").weekday()

        console.log("fill", MT.includes(weekDay))
        form.setFieldValue("normal_tickets_cost", MT.includes(weekDay) ? game.costs_tt_normal : game.costs_fs_normal)
        form.setFieldValue("reduced_tickets_cost", MT.includes(weekDay) ? game.costs_tt_reduced : game.costs_fs_reduced)
    }

    return (
        <div className="reservation-details">
            <Title level={3}>{reservation ? "Edycja rezerwacji" : "Nowa rezerwacja"}</Title>

            <div>

                <Form
                    form={form}
                    name="reservationDetails"
                    initialValues={convertDates(reservation, ["date", "YYYY-MM-DD"], [["time_start", "time_end"], "HH:mm"])}
                    layout="vertical"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: 500,
                        padding: 24,
                        background: "white"
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        label="Data rezerwacji"
                        name="date"
                        rules={[{required: true, message: "Wybierz datę!"}]}
                    >
                        <DatePicker
                            disabledDate={disabledDate}
                            onChange={onDateChange}
                        />
                    </Form.Item>

                    <Space align="center" size="large">
                        <Form.Item label="Bilety ulgowe" name="reduced_tickets" style={{width: 150}}>
                            <InputNumber min={0}/>
                        </Form.Item>
                        <Form.Item label="Cena biletu ulgowego" name="reduced_tickets_cost">
                            <InputNumber addonAfter="zł" min={0}
                                         onChange={refresh}/>
                        </Form.Item>
                    </Space>

                    <Space align="center" size="large">
                        <Form.Item label="Bilety normalne" name="normal_tickets" style={{width: 150}}>
                            <InputNumber min={0} onChange={refresh}/>
                        </Form.Item>
                        <Form.Item label="Cena biletu normalnego" name="normal_tickets_cost">
                            <InputNumber addonAfter="zł" min={0} onChange={refresh}/>
                        </Form.Item>
                    </Space>

                    <br/>

                    {addGame ? <>
                        <Title
                            level={4}
                        >
                            <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => setAddGame(false)}
                                style={{
                                    padding: 5
                                }}
                            />
                            Gra
                        </Title>

                        <Form.Item label="Gra" name={["game", "game_id"]}>
                            <GamePicker
                                form={form}
                                setGame={setSelectedGame}
                                fillTicketPrices={fillTicketPrices}
                                onChange={refresh}
                            />
                        </Form.Item>

                        <BaseReservationForm
                            form={form}
                            prefix={["game"]}
                            currentDate={currentDate}
                            calendarData={data}
                        />
                    </> : <><Button style={{marginBottom: 10}} type="dashed" onClick={() => setAddGame(true)}>Dodaj
                        grę</Button><br/></>
                    }

                    {addParty ? <>
                        <Title
                            level={4}
                        >
                            <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => {
                                    setAddParty(false)
                                    form.setFieldValue('party_type', null)
                                    refresh()
                                }}
                                style={{
                                    padding: 5
                                }}
                            />
                            Sala/Ognisko
                        </Title>

                        <Form.Item
                            name={["party", "party_type"]}
                            label="Typ"
                            rules={[{require: true, message: "Wybierz typ!"}]}
                        >
                            <PartyPicker game={selectedGame} onChange={refresh}/>
                        </Form.Item>

                        <BaseReservationForm
                            form={form}
                            prefix={["party"]}
                            currentDate={currentDate}
                            calendarData={data}
                        />
                    </> : <Button type="dashed" onClick={() => setAddParty(true)}>Dodaj Salę/Ognisko</Button>
                    }

                    <Title level={4}>Dane klienta</Title>

                    <UserInfoForm
                        required={false}
                        selectedParty={1}
                        initialValues={reservation}
                        showDescriptions={false}
                    />

                    <Title level={4}>Płatność</Title>
                    <Summary
                        reservation={reservation}
                        trigger={trigger}
                        refreshTrigger={refresh}
                        modifiableCosts={true}
                    />

                    {!reservation &&
                        <Form.Item name="generate_payment">
                            <Checkbox>Wyślij mailem link do płatności</Checkbox>
                        </Form.Item>
                    }

                    <Form.Item
                        style={{marginBottom: 0}}>
                        <Flex justify="end" align="center">
                            <Space align="end">
                                <Button type="default" onClick={() => navigate('/admin/reservations')}>
                                    Anuluj
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    {reservation ? "Zapisz" : "Utwórz"}
                                </Button>
                            </Space>
                        </Flex>
                    </Form.Item>

                </Form>

            </div>

        </div>
    )
}


export const customReservationDetailsLoader = async ({params}) => {
    const {id} = params

    if (!id) {
        return null
    }

    const response = await fetch(`/api/v1/reservations/custom/${id}`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        throw Error('Nie można pobrać danych tej rezerwacji.')
    }

    return await response.json()
}