import React, {useMemo, useState} from 'react';
import {DatePicker, Space, Spin, Typography} from "antd";
import dayjs from "dayjs";
import updateLocale from 'dayjs/plugin/updateLocale'
import useFetch from "../../../hooks/useFetch";

const {Text, Title} = Typography;

const dateFormat = "YYYY-MM-DD"

require('dayjs/locale/pl')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(updateLocale)
dayjs.locale('pl')
const ReservationDatePicker = ({dependence, trigger, form, reservation_id, ...props}) => {
    // TODO: ustawić timezone na dockerze
    // console.log(dayjs.tz.guess())// America/Chicago)
    dayjs.updateLocale('en', {
        weekStart: 1
    })

    const getCalendarDateStart = (d) => d.startOf('month').startOf('week')

    const [datePointer, setDatePointer] = useState(getCalendarDateStart(props.defaultValue || form.getFieldValue('date') ? dayjs(form.getFieldValue('date'), 'YYYY-MM-DD') : dayjs()))
    const [isMonthLoading, setIsMonthLoading] = useState(false)
    const [dateAvailability, setDateAvailability] = useState({})

    const url = useMemo(() => {
        const data = new Map(dependence.filter(d => form.getFieldValue(d)).map(d => [d, form.getFieldValue(d)]))
        if (data.size === 0) {
            return null
        }

        const params = new URLSearchParams(data)
        // console.log("DEF", datePointer)
        params.set('date', datePointer.format('YYYY-MM-DD'))
        if (reservation_id) {
            params.set('reservation_id', reservation_id)
        }

        return `/api/v1/date-availability?${params.toString()}`
    }, [trigger, datePointer]) // dlaczego dla dependence też działa?

    const {data: calendar, isPending, error} = useFetch(`/api/v1/calendar/`)

    const {data, isPending2, error2} = useFetch(url, (status, responseData) => {
        setDateAvailability({...dateAvailability, ...responseData})
        setIsMonthLoading(false)
    })

    const disabledDate = (current) => {
        if (!current) {
            return false
        }

        if (!calendar) {
            return true
        }

        const dayInfo = calendar.week_days[current.weekday()]

        return current <= dayjs().add(-1, 'day') ||
            calendar.disabled_dates.includes(current.format('YYYY-MM-DD')) ||
            (dayInfo && !dayInfo.enabled)
    }

    const buildCellColor = (status) => {
        let color

        if (status === "red") {
            color = "255, 0, 0"
        } else if (status === "green") {
            color = "0, 255, 0"
        } else {
            color = "255, 255, 0"
        }

        return {
            border: `
            1
            px
            solid
            rgba(${color}, 0.1)`,
            background: `
            rgba( ${color}, .3)`,
            borderRadius: '15%',
        }
    }

    const cellRender = React.useCallback((current, info) => {
        if (info.type !== 'date') {
            return info.originNode
        }

        if (typeof current === 'number') {
            return <div className="ant-picker-cell-inner"> {current} < /div>
        }

        const dateString = current.format('YYYY-MM-DD')
        const dateInfo = dateAvailability[dateString]

        return (<
                div className="ant-picker-cell-inner"
                    style={dateInfo && !disabledDate(current) ? buildCellColor(dateInfo) : {}}> {current.date()} <
                /div>
        )

    }, [dateAvailability]);

    const onMonthChange = (value, mode) => {
        if (!value) {
            return;
        }

        if (mode !== "date") {
            return
        }

        setIsMonthLoading(true);
        setDatePointer(getCalendarDateStart(value))
    };

    const panelRender = (originalPanel) => {
        return <Spin spinning={isMonthLoading}>{originalPanel}</Spin>;
    }

    return (<>
            <Space align="center">
                <DatePicker disabled={isPending || isPending2 || !dateAvailability}
                            cellRender={cellRender}
                            disabledDate={disabledDate}
                            onPanelChange={onMonthChange}
                            panelRender={panelRender}
                            format={dateFormat}
                            {...props}
                />
                {(isPending || isPending2) && < Spin> < /Spin>}
            < /Space>
            {error && <Text type="danger"> Błąd: {error} < /Text>}
            {error2 && <Text type="danger"> Błąd: {error2} < /Text>}
        </>
    )
}

export default ReservationDatePicker;