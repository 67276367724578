import {useContext} from "react"
import {ReservationFormContext} from "../context/ReservationContext";

export const useReservationFormContext = () => {
    const context = useContext(ReservationFormContext)

    if (!context) {
        throw Error('useReservationFormContext must be used inside an ReservationFormProvider')
    }

    return context
}