import dayjs from "dayjs";
import {Typography} from "antd";

const {Paragraph} = Typography;

export function convertDates(obj, ...elements) {
    if (!obj) {
        return obj
    }

    elements.forEach(element => {
        let [fields, format] = element

        if (!Array.isArray(fields)) {
            fields = [fields]
        }

        fields.filter(f => obj[f]).forEach(f => obj[f] = dayjs(obj[f], format))
    })

    return obj
}

export function convertDatesToString(obj, ...elements) {
    if (!obj) {
        return obj
    }

    elements.forEach(element => {
        let [fields, format] = element

        if (!Array.isArray(fields)) {
            fields = [fields]
        }

        fields.filter(f => obj[f]).forEach(f => obj[f] = obj[f].format(format))
    })

    return obj
}

export function convertErrorInfo(data) {
    return Array.isArray(data.detail) ? data.detail.map((error) => (
        <div>
            <Paragraph>{error.loc[1]}: {error.msg}</Paragraph>
        </div>
    )) : data.detail
}

export function getFreeHoursData(form, date, reservation_id = null, customMode = false) {
    const paramsObj = new URLSearchParams()
    const fields = ["normal_tickets", "reduced_tickets"]

    if (customMode) {
        fields.push(["game", "game_id"], ['party', 'party_type'], "normal_tickets_cost", "reduced_tickets_cost")
    } else {
        fields.push("event_id", "game_id", "party_type")
    }

    console.log("XX", fields)

    fields.forEach(f => {
        const value = form.getFieldValue(f)
        if (value === undefined || value === null) {
            return
        }

        paramsObj.set(Array.isArray(f) ? f[1] : f, value)
    })

    paramsObj.set('date', date)
    if (reservation_id) {
        paramsObj.set('reservation_id', reservation_id)
    }

    return paramsObj.toString()
}

export function getCalculateCostsData(form, date, vouchers, reservation = null, customMode = false) {
    if (date === null) {
        date = form.getFieldValue('date') || reservation?.date
    }

    if (date === null || date === undefined) {
        return null
    }

    if (typeof date !== "string") {
        date = date.format('YYYY-MM-DD', 'pl')
    }

    return getFreeHoursData(form, date, reservation?.id, customMode) + `&hour=${form.getFieldValue('hour')}&vouchers=${vouchers.join(",")}`
}