import {Button, Flex, Space, Table, Typography, Popconfirm, notification} from 'antd';
import {NavLink, useLoaderData, useNavigate} from "react-router-dom"
import {useState} from "react";
import {useMobileBreakpoint} from "../../../hooks/useMobileBreakpoint";
import dayjs from "dayjs";

const {Title} = Typography;
export default function Logs() {
    const data = useLoaderData()
    const {isMobile} = useMobileBreakpoint()
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification()
    const [dataSource, setDataSource] = useState(data.map(d => {
        d['dt'] = dayjs(d.created_at).format('YYYY-MM-DD HH:mm')
        d['key'] = d.id

        // Fix new lines
        d['parsed_content'] = d.content.split('\n').map((item, i) => {
            return <p key={i}>{item}</p>;
        })

        return d
    }))

    const columns = [
        {
            title: 'Użytkownik',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: 'Data',
            dataIndex: 'created_at',
            key: 'created_at',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.dt - b.dt
        },
        {
            title: 'Akcja',
            dataIndex: 'action',
            key: 'action',
            filters: [
                {
                    text: "Nowy",
                    value: "Nowy",
                },
                {
                    text: "Aktualizacja",
                    value: "Aktualizacja",
                },
                {
                    text: "Usunięcie",
                    value: "Usunięcie",
                }
            ],
            onFilter: (value, record) => record.action.indexOf(value) === 0
        },
        {
            title: 'Obiekt',
            dataIndex: 'object_type',
            key: 'object_type',
            filters: [
                {
                    text: "Rezerwacja",
                    value: "Rezerwacja",
                },
                {
                    text: "Użytkownik",
                    value: "Użytkownik",
                },
                {
                    text: "Gra",
                    value: "Gra",
                },
                {
                    text: "Wydarzenie",
                    value: "Wydarzenie",
                },
                {
                    text: "Voucher",
                    value: "Voucher",
                }
            ],
            onFilter: (value, record) => record.object_type.indexOf(value) === 0,
        },
        {
            title: 'Id',
            dataIndex: 'object_id',
            key: 'object_id'
        },
        {
            title: 'Informacja',
            dataIndex: 'parsed_content',
            key: 'parsed_content'
        }
    ]

    return (
        <div className="logs">
            {contextHolder}

            <Flex justify="space-between" align="center">
                <Title level={3}>Logi</Title>
            </Flex>
            <Table dataSource={dataSource} columns={isMobile ? columns.filter(c => c.key === "user" || c.key === "action" || c.key === "info") : columns}/>
        </div>
    )
}


export const logsLoader = async () => {
    const response = await fetch('/api/v1/logs/', {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })
    
    if (!response.ok) {
        throw Error('Could not fetch the list of events')
    }
    
    return await response.json()
}
