import {
    Badge,
    Button,
    Col,
    DatePicker,
    Dropdown,
    Flex,
    Form,
    Input,
    Popconfirm,
    Row,
    Space,
    Switch,
    Table,
    Typography
} from 'antd';
import {DownOutlined} from '@ant-design/icons';

import {NavLink, useLoaderData, useNavigate, useSearchParams} from "react-router-dom"

import {useEffect, useState} from "react";
import {useMobileBreakpoint} from "../../../hooks/useMobileBreakpoint";
import {useNotificationContext} from "../../../hooks/useNotificationContext";

import dayjs from "dayjs";
import {useAuthContext} from "../../../hooks/useAuthContext";

const {Text, Title} = Typography;
const {RangePicker} = DatePicker;

const dateFormat = 'YYYY-MM-DD';
const dtFormat = 'YYYY-MM-DD HH:mm';
const timeFormat = 'HH:mm';

export default function Reservations() {
    const [form] = Form.useForm()
    const {isMobile} = useMobileBreakpoint()
    const navigate = useNavigate()
    const xd = useLoaderData()
    const {api} = useNotificationContext()
    const {user, group} = useAuthContext()
    const [loading, setLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams({
        date_range: `${dayjs().format(dateFormat)},${dayjs().add(7, "day").format(dateFormat)}`,
        search_text: "",
        compact_view: true
    })

    const [dataSource, setDataSource] = useState([])

    const additionalButtons = [
        {
            key: 'create',
            label: <NavLink to="/admin/reservations/create">Standardowa</NavLink>
        },
        {
            key: 'create-custom',
            label: <NavLink to="/admin/reservations/create-custom">Niestandardowa</NavLink>
        }
    ]

    const partyButton = [
        {
            key: 'create-party',
            label: <NavLink to="/admin/reservations/create-party">Sala/Ognisko</NavLink>
        }
    ]

    const menuToAdd = ["other_firm", "party_editor"].includes(group) ? partyButton : [...additionalButtons, ...partyButton]

    const convertParams = () => {
        const def = Object.fromEntries(searchParams)

        if (def.date_range) {
            const dates = def.date_range.split(',')
            def.date_range = [dayjs(dates[0], dateFormat), dayjs(dates[1], dateFormat)]
        }

        return def
    }

    const columns = [
        {
            title: 'Czas rezerwacji',
            dataIndex: 'dt',
            key: 'dt',
            render: (_, record) => {
                const {game, party} = record

                return (
                    <Space size="middle" direction="vertical">
                        {game &&
                            <Text>{dayjs(game.dt_start).format(dtFormat)} - {dayjs(game.dt_end).format(timeFormat)}</Text>}
                        {party &&
                            <Text>{dayjs(party.dt_start).format(dtFormat)} - {dayjs(party.dt_end).format(timeFormat)}</Text>}
                    </Space>
                )
            }
        },
        {
            title: 'Trwanie',
            dataIndex: 'duration',
            key: 'duration',
            render: (_, record) => {
                const {game, party} = record

                return (
                    <Space direction="vertical">
                        {game && <Text>{game.duration} min</Text>}
                        {party && <Text>{party.duration} min</Text>}
                    </Space>
                )
            }
        },
        {
            title: 'Typ',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => (
                <Space direction="vertical">
                    {record.game && <Badge color={record.game.color} text={record.game.type}/>}
                    {record.party && <Badge color={record.party.color} text={record.party.type}/>}
                </Space>
            )
        },
        {
            title: 'Bilety ulg/norm',
            dataIndex: 'tickets',
            key: 'tickets'
        },
        {
            title: 'Płatność',
            dataIndex: 'payment',
            key: 'payment',
        },
        {
            title: 'Dane kontaktowe',
            dataIndex: 'user_info',
            key: 'user_info'
        },
        {
            title: 'Komentarz',
            dataIndex: 'comment',
            key: 'comment',
            render: (_, record) => (
                <Text>{((record.game && record.game.user_comment) || (record.party && record.party.user_comment)) ? "Tak" : "Nie"}</Text>
            )
        },
        {
            title: 'Parking',
            dataIndex: 'parking_data',
            key: 'parking_data'
        }
    ]

    const deleteReservation = async (reservationId) => {
        const response = await fetch(`/api/v1/reservations/${reservationId}`, {
            credentials: 'include',
            method: "DELETE",
            headers: {
                'Content-Type': 'applications/json'
            }
        })

        if (!response.ok) {
            const error = response.status === 500 ? "Nieznany błąd!" : await response.json()

            api['error']({
                message: 'Błąd',
                description: error.detail
            })

        } else {
            api['success']({
                message: 'Sukces',
                description: `Usunięto rezerwację.`
            })

            setDataSource(dataSource.filter(reservation => {
                const {game, party} = reservation
                const obj = game || party

                return obj.id !== reservationId && obj.parent_id !== reservationId
            }))
        }
    }

    const applyFilters = async (values) => {
        setLoading(true)

        const dateRange = values.date_range
        if (Array.isArray(dateRange)) {
            values.date_range = `${dateRange[0].format(dateFormat)},${dateRange[1].format(dateFormat)}`
        }

        try {
            const data = await reservationsLoader(new URLSearchParams(values).toString())
            setDataSource(data)
            setSearchParams(values)
        } catch (e) {
            api['error']({
                message: 'Błąd wyszukiwania!',
                description: `${e}`
            })
        }

        setLoading(false)
    }

    useEffect(() => {
        applyFilters(Object.fromEntries(searchParams))
    }, [])

    return (
        <div className="events">
            <Flex justify="space-between" align="center">
                <Title level={3}>Rezerwacje</Title>

                {group !== "reservation_viewer" &&
                    <Dropdown menu={{
                        items: menuToAdd
                        // onClick: () => navigate('/admin/reservations/create'),
                    }}
                              trigger={['click']}
                    >
                        <Button type="primary">
                            <Space>
                                Utwórz
                                <DownOutlined/>
                            </Space>
                        </Button>
                    </Dropdown>
                }

            </Flex>

            <Title level={4}>Filtry</Title>
            <Space
                direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                    marginBottom: 20
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={convertParams()}
                    // labelCol={{
                    //     span: 8,
                    // }}
                    // wrapperCol={{
                    //     span: 8,
                    // }}
                    onFinish={applyFilters}
                    autoComplete="off"
                >
                    <Row gutter={[32, 32]}>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                label="Okres"
                                name="date_range"
                                layout="vertical"
                                rules={[
                                    {
                                        required: true,
                                        message: "Wyznacz okres!"
                                    }
                                ]}
                            >
                                <RangePicker
                                    // defaultValue={}
                                    format={dateFormat}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>

                            <Form.Item
                                label="Tekst"
                                name="search_text"
                                layout="vertical"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            {group !== "other_firm" &&
                                <Form.Item
                                    label="Pokaż gry"
                                    name="compact_view"
                                    layout="vertical"
                                    valuePropName="checked"
                                >
                                    <Switch/>
                                </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Form.Item
                        style={{marginBottom: 0}}>
                        <Space align="end">
                            <Button htmlType="submit" loading={loading}>
                                Filtruj
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Space>

            <Table
                dataSource={dataSource}
                columns={isMobile ? columns.filter(c => c.key === "dt" || c.key === "type" || c.key === "user_info") : columns}
                expandable={{
                    expandedRowRender: (record) => {
                        const obj = record.game || record.party
                        const renderPartySection = record.party != null && record.game != null
                        const partyObj = record.party

                        // /admin/reservations/edit/:id
                        // /admin/reservations/custom/edit/:id
                        // /admin/reservations/party/edit/:id
                        let prefixEditPath = ""

                        console.log(record, record.is_custom)
                        if (obj.is_custom) {
                            prefixEditPath = "custom/"
                        } else if (record.party && !record.party.parent_id) {
                            // Tylko podczas rezerwacji samej sali/ogniska
                            prefixEditPath = "party/"
                        }

                        let editPath = `${prefixEditPath}edit/${record.key.toString()}`

                        return (
                            <div className="reservation_info">
                                <Space
                                    direction="vertical"
                                    size="small"
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Text>Tytuł: {obj.name || "-"}</Text>
                                    <Text>Opis: {obj.description || "-"}</Text>
                                    <Text>Rozpoczęcie rezerwacji: {dayjs(obj.dt_start).format(dtFormat)}</Text>
                                    <Text>Zakończenie rezerwacji: {dayjs(obj.dt_end).format(dtFormat)}</Text>
                                    <Text>Czas trwania{record.game ? ' gry' : ''}: {obj.duration}min</Text>
                                    <Text>Typ rezerwacji: <Space size="middle">
                                        <Badge color={obj.color} text={obj.type}/>
                                    </Space>
                                    </Text>
                                    <Text>Bilety: {record.tickets}</Text>
                                    <Text>Cena biletów: {record.tickets_cost}</Text>
                                    <Text>Płatność: {record.payment}</Text>
                                    <Text>Klient: {record.user_info}</Text>
                                    <Text>Komentarz klienta: {obj.user_comment}</Text>
                                    <Text>Parking: {obj.parking_data}</Text>

                                    {renderPartySection && <>

                                        <Title level={4}>{partyObj.type}</Title>

                                        <Text>Rozpoczęcie rezerwacji: {dayjs(partyObj.dt_start).format(dtFormat)}</Text>
                                        <Text>Zakończenie rezerwacji: {dayjs(partyObj.dt_end).format(dtFormat)}</Text>
                                        <Text>Czas trwania: {partyObj.duration}min</Text>
                                        <Text>Typ rezerwacji: <Space size="middle">
                                            <Badge color={partyObj.color} text={partyObj.type}/>
                                        </Space>
                                        </Text>

                                    </>}

                                </Space>

                                <Space
                                    direction="vertical"
                                    size="small"
                                    style={{
                                        display: 'flex',
                                        marginTop: 20
                                    }}>
                                    <Text>Rezerwację utworzył: {record.creator_username}</Text>
                                </Space>


                                {dayjs(obj.dt_start) >= dayjs() &&
                                    <Space size="middle" style={{marginTop: 20}}>
                                        {/*TODO*/}
                                        {/*{*/}
                                        {/*    record.game && group !== "reservation_viewer" &&*/}
                                        {/*    <Button type="primary">*/}
                                        {/*        <NavLink to="">Dodaj Voucher</NavLink>*/}
                                        {/*    </Button>*/}
                                        {/*}*/}

                                        {group !== "reservation_viewer" &&
                                            <>
                                                <Button
                                                disabled={!record.edit_permission}>
                                                    <NavLink to={editPath}>Edytuj</NavLink>
                                                </Button>
                                                <Popconfirm
                                                    placement="bottomRight"
                                                    title="Usunięcie rezerwacji"
                                                    description="Czy chcesz usunąć rezerwację?"
                                                    onConfirm={() => deleteReservation(record.key)}
                                                    okText="Tak"
                                                    cancelText="Nie"
                                                >
                                                    <Button
                                                        disabled={!record.delete_permission}
                                                        danger
                                                    >Usuń</Button>
                                                </Popconfirm>
                                            </>
                                        }
                                    </Space>
                                }

                            </div>
                        )
                    },
                rowExpandable: (record) => true
                }}
                />
        </div>
    )
}

    export const fakeLoader = async (params) => {
        return null
    }
    export const reservationsLoader = async (params) => {
        const response = await fetch(`/api/v1/reservations/?${params}`, {
            credentials: 'include',
            headers: {
                'Content-Type': 'applications/json'
            }
        })

        if (!response.ok) {
            throw Error('Could not fetch the list of reservations.')
        }

        const data = await response.json()

        return data.map(reservation => {
            const {game, party} = reservation
            const obj = game || party

            return {
                ...reservation,
                key: obj.id,
                duration: `${obj.duration}min`,
                tickets: `${obj.reduced_tickets || '-'}/${obj.normal_tickets || '-'}`,
                tickets_cost: `${obj.reduced_tickets_cost || '-'}/${obj.normal_tickets_cost || '-'}`,
                payment: `${obj.paid?.toFixed(2) || '-'}/${obj.cost?.toFixed(2) || '-'}`,
                user_info: `${obj.user_name || ''} ${obj.user_surname || ''} - ${obj.user_phone || ''}`,
                parking_data: obj.parking_data || '-'
            }
        })

    }
