import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, Descriptions, Form, Input, InputNumber, Radio, Row, Space, Spin, Typography} from "antd";
import {MinusCircleOutlined} from '@ant-design/icons';
import {mapPartyType} from "../../parties/Parties";
import useFetch from "../../../../hooks/useFetch";
import {convertErrorInfo, getCalculateCostsData} from "../../../../utils/utils";
import {useReservationFormContext} from "../../../../hooks/useReservationFormContext";
import Costs from "./Costs";

const {Text, Title} = Typography;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 4,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 20,
        },
    },
};

const Summary = ({
                     reservation = null,
                     trigger,
                     refreshTrigger,
                     setResultData,
                     modifiableCosts = false,
                     useStandardCalculations = false
                 }) => {
    const {
        form,
        vouchersList,
        selectedEvent,
        setVouchersList,
        selectedGame,
        loadedGamesParties,
        selectedParty
    } = useReservationFormContext()

    const [voucher, setVoucher] = useState(null)
    const [voucherError, setVoucherError] = useState(null)
    const partyName = mapPartyType(form.getFieldValue('party_type'))

    const getCurrentParty = () => {
        return loadedGamesParties.parties.find(party => party.name === form.getFieldValue("party_type"))
    }

    const refreshCosts = () => {
        if (!voucher) {
            setVoucherError('Wprowadź kod!')
            return
        }

        if (voucher.toUpperCase() in vouchersList) {
            setVoucherError('Ten kod jest już użyty!')
            return
        }

        setVoucherError(null)
        refreshTrigger()
    }

    const url = useMemo(() => {
        console.log("trigg", vouchersList, voucher)

        const params = getCalculateCostsData(form, null, [...vouchersList, voucher], reservation, modifiableCosts)
        if (params === null) {
            console.log("trigg stopped")
            return null
        }


        return `/api/v1/${useStandardCalculations ? 'calculate-costs' : 'calculate-custom-reservation-costs'}?${params}`
    }, [trigger])

    const {data, isPending, error, setError} = useFetch(url, (status, data) => {
        console.log("resp", voucher, status)
        if (voucher && status === 200) {
            setVouchersList([...vouchersList, voucher])
            setVoucher(null)
        }

        if (status === 200) {
            setResultData(data)

            // TODO: sprawdzenie ładowania danych z bazy
            form.setFieldValue('cost', data.cost_with_discount)
            form.setFieldValue('advance', data.advance)
        }
    })

    const renderCostsSection = () => {

    }

    useEffect(() => {
        console.log("is_full_payment", form.getFieldValue('is_full_payment'), form.getFieldValue('is_full_payment') === false)
        if (form.getFieldValue('is_full_payment') === false) {
            form.setFieldValue('advance', form.getFieldValue('payment'))
        }
    }, [])


    if (isPending) {
        console.log("Pending")
        return <Spin spinning={true}></Spin>
    }

    if (error && error.detail && !error.detail.startsWith('VOUCHER_')) {
        return <Text type="danger">{convertErrorInfo(error)}</Text>
    }

    if (selectedGame === undefined || selectedGame == null) {
        return <Text>NO GAME</Text>
    }

    let date = form.getFieldValue('date')
    if (typeof date !== "string") {
        date = date.format('YYYY-MM-DD', 'pl')
    }

    if (!data) {
        console.log("Pending !data")
        return <Spin spinning={true}></Spin>
    }

    console.log("selectedEvent", selectedEvent)

    const items = [
        {
            key: 1,
            label: "Wydarzenie",
            children: selectedEvent?.name
        },
        {
            key: 1,
            label: "Gra",
            children: selectedGame.name
        },
        {
            key: 2,
            label: "Bilety ulgowe",
            children: `${form.getFieldValue('reduced_tickets') || 0} x ${data.reduced_ticket_cost?.toFixed(2)}zł
             = ${((form.getFieldValue('reduced_tickets') || 0) * data.reduced_ticket_cost)?.toFixed(2)}zł`
        },
        {
            key: 3,
            label: "Bilety normalne",
            children: `${form.getFieldValue('normal_tickets') || 0} x ${data.normal_ticket_cost?.toFixed(2)}zł
                    = ${((form.getFieldValue('normal_tickets') || 0) * data.normal_ticket_cost)?.toFixed(2)}zł`
        },
        {
            key: 4,
            label: partyName ? partyName : "Sala/Ognisko",
            children: data.party_cost ? `${data.party_cost}zł` : "-"
        },
        {
            key: 5,
            label: "Całkowity koszt",
            children: <span><s>{data.cost !== data.cost_with_discount ? data.cost?.toFixed(2) : ""}</s>{data.cost_with_discount?.toFixed(2)}zł</span>
        },
        {
            key: 6,
            label: "Kod rabatowy",
            children: data.discount_code ? `-${data.discount_code}%` : 'Brak'
        },
        // {
        //     key: 7,
        //     label: "Vouchery",
        //     children: data.vouchers_discount ? `${data.vouchers_discount}zł` : 'Brak'
        // },
        {
            key: 8,
            label: "Termin rozpoczęcia rezerwacji",
            children: `${date} ${form.getFieldValue('hour') ? form.getFieldValue('hour') : ""}`
        },
        {
            key: 9,
            label: "Czas gry",
            children: loadedGamesParties && `${selectedGame.game_time}min`
        },
        {
            key: 10,
            label: "Czas sali/ogniska",
            children: loadedGamesParties && `${getCurrentParty() ? getCurrentParty().time : ''}${getCurrentParty() ? "min" : "-"}`
        }
    ]

    return <div>

        <Descriptions
            title="Podsumowanie"
            column={1}
            bordered="true"
            size="small"
            items={items}
        />

        <Title level={5}>Wybierz formę płatności</Title>

        <Row>
            <Col span={18}>
                <Costs
                    form={form}
                    modifiableCosts={modifiableCosts}
                    data={data}
                />

                {vouchersList.length > 0 && <Text>Vouchery / Kody rabatowe:</Text>}
                {vouchersList.map((field) => (
                    <Form.Item
                        {...formItemLayout}
                        required={false}
                        key={field}
                    >
                        <Form.Item
                            noStyle
                        >
                            <Input
                                disabled={true}
                                defaultValue={field}
                                style={{
                                    width: '60%',
                                    marginRight: 10
                                }}
                            />
                        </Form.Item>
                        <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => {
                                setVouchersList(vouchersList.filter(v => v !== field))
                                refreshTrigger()
                            }}
                        />
                    </Form.Item>
                ))}
                <Form.Item
                    label="Wpisz numer vouchera / kodu rabatowego"
                >
                    <Space.Compact>
                        <Input value={voucher} onChange={(e) => setVoucher(e.target.value.toUpperCase())}/>
                        <Button onClick={refreshCosts}>Dodaj</Button>
                    </Space.Compact>
                    {(voucherError || (error && error.detail && error.detail.startsWith('VOUCHER_'))) &&
                        <div><Text type="danger">{voucherError || error?.detail}</Text></div>}
                </Form.Item>

            </Col>
        </Row>

    </div>
}

export default Summary;


