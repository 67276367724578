import {Badge, Button, Card, Col, Modal, Row, Typography} from "antd";
import {useState} from "react";

const {Title, Text} = Typography;

const sharedOnCell = (_, index) => {
    if (index === 1) {
        return {
            colSpan: 0,
        };
    }
    return {};
};

const data = [
    {
        key: 'wt-czw',
        name: 'John Brown',
        wt: '29/35 zł od osoby',
        tel: '0571-22098909',
        phone: 18889898989,
        address: 'New York No. 1 Lake Park',
    },
    {
        key: 'pt-niedz',
        name: 'Jim Green',
        tel: '0571-22098333',
        phone: 18889898888,
        age: 42,
        address: 'London No. 1 Lake Park',
    }
]

const columns = [
    {
        title: '',
        dataIndex: 'key',
        rowScope: 'row',
    },
    {
        title: '30 min gry (1h rezerwacji)\n' +
            'ulgowy / normalny',
        dataIndex: 'wt',
        onCell: sharedOnCell,
    },
    {
        title: '60 min gry (1,5h rezerwacji) ulgowy / normalny',
        dataIndex: 'age',
        onCell: sharedOnCell,
    },
    {
        title: '90 min gry (2h rezerwacji)\n' +
            'ulgowy / normalny',
        dataIndex: 'age',
        onCell: sharedOnCell,
    }
]

const renderBadge = ({condition, children}) => {
    if (condition) {
        return <Badge.Ribbon text="WYBRANO">{children}</Badge.Ribbon>
    }

    return <>{children}</>
}

const GameCard = ({game, selectedGame, setSelectedGame, setSelectGameError}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        setSelectedGame(game)
        setSelectGameError(false)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Col span={12}>

            {renderBadge({
                condition: selectedGame && game.id === selectedGame.id,
                children: <Card style={{backgroundColor: "#0a0a5c", height: 150}} hoverable onClick={showModal}>
                    <Row align="middle" justify="center">
                        <Col><Title level={5} strong style={{color: "white"}}>{game.name.toUpperCase()}</Title></Col>
                    </Row>
                </Card>
            })}

            <Modal
                title={game.name.toUpperCase()}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Zamknij
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Wybierz
                    </Button>
                ]}
            >
                <Title level={5}>Opis</Title>
                <Text>{game.description}</Text>

                <Title level={5}>Liczba osób</Title>
                <Text>{game.min_participants}-{game.max_participants}</Text>

                <Title level={5}>Wiek</Title>
                <Text>{game.max_age ? `${game.min_age}-${game.max_age}` : `${game.min_age}+`}</Text>

                <Title level={5}>Cennik pn-czw</Title>
                <Text>Bilet ulgowy: {game.costs_tt_reduced}zł</Text><br/>
                <Text>Bilet normalny: {game.costs_tt_normal}zł</Text>

                <Title level={5}>Cennik pt-ndz</Title>
                <Text>Bilet ulgowy: {game.costs_fs_reduced}zł</Text><br/>
                <Text>Bilet normalny: {game.costs_fs_normal}zł</Text>

                {/*<Table columns={columns} dataSource={game} bordered/>*/}
            </Modal>

        </Col>
    )
}

export default GameCard;