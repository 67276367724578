import React, {useEffect, useState} from 'react';
import {Form, InputNumber, Radio, Space, Typography} from "antd";

const {Text} = Typography;

const Costs = ({form, modifiableCosts, data}) => {
    // const [advanceError, setAdvanceError] = useState("")
    //
    // const updateAdvanceRequired = () => {
    //     // Wymagaj wartości zaliczki jeżeli wybrano zaliczke
    //     console.log(form.getFieldValue('is_full_payment'))
    //     if (form.getFieldValue('is_full_payment') === false && !form.getFieldValue('advance')) {
    //         setAdvanceError("Wprowadź kwotę zaliczki!")
    //         return false
    //     } else {
    //         setAdvanceError("")
    //         return true
    //     }
    //
    // }

    useEffect(() => {
        console.log("Cost fupdate", data)
        if (modifiableCosts) {
            form.setFieldValue('cost', data.cost_with_discount)
        }

        // Jeżeli wybrał zaliczkę, a po przeliczeniu nie ma dostępnej opcji zaliczki,, to usuń zaznaczenie
        if (!data.advance && form.getFieldValue('is_full_payment') === false) {
            console.log('reset radio')
            // form.setFieldValue('is_full_payment', undefined)
        }
    }, [data])

    if (modifiableCosts) {
        return <>
            <Form.Item name="cost" label="Koszt całkowity">
                <InputNumber addonAfter="zł"/>
            </Form.Item>

            <Form.Item
                name="is_full_payment"
                rules={[{required: true, message: "Wybierz formę płatności!"}]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={true} >Całość</Radio>
                        <Radio value={false}>
                            <Space>
                                Zaliczka
                                <InputNumber name="advance"
                                             addonAfter="zł"
                                    // value={form?.getFieldValue('advance') || data?.advance}
                                             onChange={(v) => form.setFieldValue('advance', v)}
                                />
                            </Space>
                        </Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>

        </>
    }

    return <>
        <Form.Item
            name="is_full_payment"
            rules={[{required: true, message: "Wybierz formę płatności!"}]}
        >
            <Radio.Group>
                <Space direction="vertical">

                    {data.advance ?
                        <Radio
                            value={false}
                            disabled={!data.advance && data.advance_tickets}
                        >Zaliczka<br/><Text type="secondary">{data.advance}zł
                            z {data.cost}zł
                            (Płacisz
                            za {data.advance_tickets} bilety)</Text></Radio>

                        :

                        <Radio
                            value={false}
                            disabled={true}
                        >
                            Zaliczka<br/><Text type="secondary">Opcja dostępna przy rezerwacji co
                            najmniej {data.advance_tickets} biletów.</Text>
                        </Radio>
                    }

                    <Radio value={true}>Całość<br/>
                        <Text type="secondary">
                            {data.discount ? `${data.cost_with_discount}zł po rabacie,
                                                        oszczędzasz ${data.discount}zł` : `${data.cost}zł`}
                        </Text></Radio>
                </Space>
            </Radio.Group>
        </Form.Item>
    </>
}

export default Costs;


