import {Button, Flex, Form, Input, Select, Space, Switch, Typography} from 'antd'
import {useLoaderData, useNavigate, useParams} from "react-router-dom"
import {useNotificationContext} from "../../../hooks/useNotificationContext";

const {Option} = Select;
const {Title, Paragraph} = Typography;


export default function UserDetails() {
    const [form] = Form.useForm()
    const {id} = useParams()
    const {user, groups} = useLoaderData()
    const navigate = useNavigate()
    const {api} = useNotificationContext()

    const onFinish = async (values) => {
        const response = await fetch(`/api/v1/users/${id ? id : ''}`, {
            method: user ? "PATCH" : "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })

        if (response.ok) {
            api['success']({
                message: 'Sukces',
                description: user ? `Pomyślnie zaktualizowano dane użytkownika ${values.username}.` : `Pomyślnie utworzono użytkownika ${values.username}.`
            })
            navigate('/admin/users')
        } else {
            response.json().then(data => api['error']({
                message: 'Błąd',
                description: data.detail.map((error) => (
                    <div>
                        <Paragraph>{error.loc[1]}: {error.msg}</Paragraph>
                    </div>
                ))
            }))
        }
    }

    return (
        <div className="user-details">
            <Title level={3}>{user ? "Edycja użytkownika" : "Nowy użytkownik"}</Title>

            <div>

                <Form
                    form={form}
                    name="userDetails"
                    initialValues={user}
                    layout="vertical"
                    labelCol={{
                        span: 16,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: 500,
                        padding: 24,
                        background: "white"
                    }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Nazwa użytkownika"
                        name="username"
                        rules={[
                            {
                                min: 3,
                                message: "Nazwa użytkownika musi zawierać min 3 znaki"
                            },
                            {
                                required: true,
                                message: 'Wprowadź nazwę użytkownika!',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    {!user && (
                        <Form.Item
                            label="Hasło"
                            name="password"
                            rules={[
                                {
                                    required: id,
                                    message: 'Wprowadź hasło użytkownika!',
                                },
                                {
                                    min: 3,
                                    message: 'Hasło musi zawierać conajmniej 3 znaki!'
                                }
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>

                    )}
                    {!user && (
                        <Form.Item
                            name="confirm"
                            label="Potwierdź hasło"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Potwierdź hasło!',
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Hasła różnią się od siebie!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>
                    )}

                    <Form.Item
                        label="Grupa"
                        name="group"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            placeholder="Wybierz grupę dla użytkownika"
                        >
                            {Object.keys(groups).map(key => (
                                <Option key={key}>{groups[key]}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Wysyłaj maile o zdarzeniach" name="email_notifications" valuePropName="checked">
                        <Switch/>
                    </Form.Item>


                    <Form.Item
                        style={{marginBottom: 0}}>
                        <Flex justify="end" align="center">
                            <Space align="end">
                                <Button type="default" onClick={() => navigate('/admin/users')}>
                                    Anuluj
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    {user ? "Zapisz" : "Utwórz"}
                                </Button>
                            </Space>
                        </Flex>
                    </Form.Item>

                </Form>

            </div>

        </div>
    )
}


export const userDetailsLoader = async ({params}) => {
    const {id} = params

    const groupResponse = await fetch('/api/v1/groups/', {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!groupResponse.ok) {
        throw Error('Could not fetch the list of groups')
    }

    if (!id) {
        return {
            user: null,
            groups: await groupResponse.json()
        }
    }

    const response = await fetch(`/api/v1/users/${id}`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'applications/json'
        }
    })

    if (!response.ok) {
        throw Error('Nie można pobrać danych tego użytkownika.')
    }

    return {
        user: await response.json(),
        groups: await groupResponse.json()
    }
}